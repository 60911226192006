import fetch from 'isomorphic-fetch';
import { getJsonOrRedirect } from 'shared/libs/urlhandling';
import { PROGRESS_RESULT } from '.././types';
import { CompareBarActions } from 'shared/general/comparebar/types';

function requestCompare(status) {
	return {
		type: CompareBarActions.REQUEST_COMPARE,
		status,
	};
}

function receiveCompare(data, status) {
	return {
		type: CompareBarActions.RECEIVE_COMPARE,
		data,
		status,
	};
}

export function fetchCompare(url: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestCompare(PROGRESS_RESULT.WAITING));

		Promise.resolve()
			.then(() =>
				fetch(`${url}`, {
					method: 'get',
					credentials: 'same-origin',
				})
			)
			.then(getJsonOrRedirect)
			.then((data) => dispatch(receiveCompare(data, PROGRESS_RESULT.SUCCESS)))
			.catch((error) => dispatch(receiveCompare(error, PROGRESS_RESULT.ERROR)));
	};
}
