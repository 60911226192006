import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import Loading from 'shared/components/loading';
import { useSpring, animated } from 'react-spring';

interface IProps {
	showPopup: boolean;
	closePopupHandler: Function;
	loading?: boolean;
	title: string;
	popupClass?: string;
	callBack?: Function;
	children: any;
}

const Popup: React.FC<IProps> = ({
	showPopup,
	closePopupHandler,
	loading,
	title,
	popupClass,
	children,
	callBack, //for future use
}) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const handleClosePopup = (e) => {
		e.preventDefault();
		onClose();
	};
	const handleCloseByLayer = (e) => {
		if (e.target === containerRef.current) {
			handleClosePopup(e);
		}
	};

	const [contentOpen, setContentOpen] = useState(false);

	const transformOpen = 'scale3d(1, 1, 1)';
	const transformClose = 'scale3d(0, 0, 0)';

	const [animation, api] = useSpring(() => ({
		transform: transformOpen,
		config: { duration: 200, },
	}));

	const startAnimation = (myConfig, onFinishedCallback) => {
		const promise = api.start(myConfig);
		if (promise.length === 1) {
			promise[0].then((result) => {
				if (result.finished) {
					onFinishedCallback();
				}
			});
		}
	};

	useEffect(() => {
		if (showPopup) {
			startAnimation({ transform: transformOpen, height: '100vh', config: { duration: 200 } }, () => {
				setContentOpen(true);
			});
		}
	}, [showPopup]);

	const onClose = () => {
		startAnimation(
			{ transform: transformClose, height: '100vh', config: { duration: 200 } },
			() => {
				setContentOpen(false);
				closePopupHandler();
			}
		);
	};

	return (
		<>
			{showPopup ? (
				<Portal closeOnEsc closeOnOutsideClick onClose={handleClosePopup} isOpened={true}>
					<section
						className={`modal-base ${popupClass}`}
						ref={containerRef}
						onClick={handleCloseByLayer}
					>
						<animated.div style={animation}>
							<div className='modal-wrapper'>

								<header className='modal-heading'>
									<h1 className='head'>{title}</h1>
									<button type='button' className='modal-heading-close' onClick={handleClosePopup}>
										<i className='cor-icon-cross'></i>
									</button>
								</header>

								<div className='modal-content'>
									{(!contentOpen || loading) && <Loading />}
									{contentOpen && <>{children}</>}
								</div>
							</div>
						</animated.div>
					</section>
				</Portal>
			) : null}
		</>
	);
};
export default Popup;
