import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createGeneralReducers } from 'shared/general';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Define the Reducers that will always be present in the application
const staticReducers = {
	...createGeneralReducers(),
};

export default function configureStore(preloadedState) {
	const logger = createLogger({
		collapsed: true,
		predicate: (getState, action) =>
			//add or remove actions that do not need to be included in redux-logger
			action.type === 'ADD_DATA',
		//...other options
	});

	let middleware = [thunkMiddleware];
	if (process.env.NODE_ENV !== 'production') {
		middleware = [...middleware, logger];
	}

	const store = createStore(
		createReducer(),
		preloadedState,
		composeEnhancers(applyMiddleware(...middleware))
	);

	// Add a dictionary to keep track of the registered async reducers
	store.asyncReducers = {};

	// Create an inject reducer function
	// This function adds the async reducer, and creates a new combined reducer
	store.injectReducer = (key, asyncReducer) => {
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	};

	window.store = store;

	return store;
}

function createReducer(asyncReducers) {
	return combineReducers({
		...staticReducers,
		...asyncReducers,
	});
}
