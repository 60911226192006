import React, { FC, useEffect, useState } from 'react';
import createConnect from 'shared/general/comparebar/connect';

interface IProps {
	data: object;
	general: any;
	comparebar: any;
	fetchCompare: Function;
	reactId: Text;
}

const CompareBar: FC<IProps> = (props) => {
	const { general, comparebar, fetchCompare, reactId } = props;

	const [showAddMessage, setShowAddMessage] = useState(false);
	const [currentItemsInCompare, setCurrentItemsInCompare] = useState(0);
	const [activeTimeout, setActiveTimeout] = useState(0);

	if (general?.compareCountUrl) {
		useEffect(() => {
			fetchCompare(general.compareCountUrl);
		}, []);
	}

	const runAnimation = () => {
		let el = document.getElementById('compareBar');

		// reset timer if already running
		if (activeTimeout) {
			el?.classList.remove('addItemToCompare');
			clearTimeout(activeTimeout);
			setActiveTimeout(0);
		}

		el?.classList.add('addItemToCompare');
		const timeOut: number = window.setTimeout(() => {
			el?.classList.remove('addItemToCompare');
			setActiveTimeout(0);
		}, 800);
		setActiveTimeout(timeOut);
	};

	if (comparebar?.data) {
		const { numberOfItemsInCompare, maxReached } = comparebar;

		if (numberOfItemsInCompare > currentItemsInCompare) {
			runAnimation();
			setCurrentItemsInCompare(numberOfItemsInCompare);
		}

		const isNotComparePage = (window['__MASTER_REACT_APP__'] !== 'compareContainer') && (window['__MASTER_REACT_APP__'] !== 'checkoutContainer');
		const hasCompareItems = numberOfItemsInCompare > 0;
		const showCompareBar = isNotComparePage && hasCompareItems;

		return (
			<>
				{showCompareBar && (
					<>
						{maxReached && (
							<div className='alert-warning mb-0'>
								<strong>{general.compareWarning}</strong>
							</div>
						)}
						{hasCompareItems ? (
							<a href={general.compareUrl}>
								{general.compareTitle}
								<em className='sticker-amount'>{numberOfItemsInCompare}</em>
							</a>
						) : (
							<>
								{showAddMessage && (
									<div className='alert-warning mb-0'>
										<strong>{general.compareMinWarning}</strong>
									</div>
								)}
								<div
									onClick={() => setShowAddMessage(!showAddMessage)}
									onMouseOut={() => setShowAddMessage(false)}
								>
									{general.compareTitle}
									<em className='sticker-amount'>{numberOfItemsInCompare}</em>
								</div>
							</>
						)}
					</>
				)}
			</>
		);
	}

	return null;
};

const connectContext = createConnect();
export default connectContext(CompareBar);
