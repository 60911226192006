import React from 'react';

export default class ProductImageElement extends React.Component {
	render() {
		const { images, name } = this.props;

		let productImageElement = false;

		if (images) {
			var productImage = images.find(function(image) {
				return image.name == '320x320';
			});
			if (productImage) {
				productImageElement = <img src={productImage.imageLink} data-src-at2x='' alt={name} />;
			}
		}

		return productImageElement;
	}
}
