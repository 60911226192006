import { IStockShopState, StockShopActions } from './types';
import produce from 'immer';
import {
	ApplicationActions,
	ApplicationActionTypes,
} from 'shared/reduceractions/getApplicationData';
import { ProductActions, ProductActionTypes } from 'shared/reduceractions/getProducts';
import { ProgressStatus } from 'shared/types';

const initialState: IStockShopState = {
	reactId: '',
	button: '',
	//application data
	localizations: {},
	extensions: {},
	synchronizerToken: undefined,
	initial: true,
	//product data
	product: undefined,
	productDataResult: undefined,
	//stockshop data
	stockShops: undefined,
	stockShopsResult: undefined,
	filterStock: false,
};

export const stockShopReducer = (reactId: string, button: string) => {
	const componentInitialState: IStockShopState = Object.assign({}, initialState, {
		reactId: reactId,
		button: button,
	});
	const reducer = (state = componentInitialState, action): IStockShopState => {
		return stockShop(state, action);
	};
	return reducer;
};

const stockShop = produce(
	(draft = initialState, action): IStockShopState => {
		if (!draft) {
			return initialState;
		}
		switch (action.type) {
			case ApplicationActions.REQUEST_APPLICATION_DATA: {
				draft.product = undefined;
				return draft;
			}

			case ApplicationActions.RECEIVE_APPLICATION_DATA: {
				const { result, data } = action;
				if (!result?.error) {
					draft.extensions = data?.extensions;
					draft.synchronizerToken = data?.synchronizerToken;
					draft.localizations = data?.localizations;
					draft.initial = false;
				}

				return draft;
			}

			case StockShopActions.REQUEST_STOCKSHOP_CLEAR: {
				//clear everything except localizations/extensions
				draft.product = undefined;
				draft.productDataResult = undefined;
				draft.stockShops = undefined;
				draft.stockShopsResult = undefined;
				break;
			}

			case StockShopActions.REQUEST_STOCKSHOP: {
				draft.stockShopsResult = 'waiting';
				break;
			}
			case StockShopActions.RECEIVE_STOCKSHOP: {
				const { postResult, data } = action;
				let progressStatus: ProgressStatus = 'error';
				if (!postResult?.error) {
					progressStatus = 'success';
				}
				draft.stockShopsResult = progressStatus;
				if (data) {
					draft.stockShops = data;
				}

				break;
			}
			case StockShopActions.REQUEST_SETSTOCKSHOPCONTEXT: {
				const { sku } = action;
				draft.sku = sku;
				break;
			}
			case StockShopActions.UPDATE_STOCKSHOP_FILTER: {
				const { filterStock } = action;
				draft.filterStock = filterStock;
				break;
			}
			case ProductActions.REQUEST_PRODUCT_DATA: {
				const { context } = action;
				draft.productDataResult = 'waiting';
				break;
			}
			case ProductActions.RECEIVE_PRODUCT_DATA: {
				const { product, postResult, context } = action;

				let progressStatus: ProgressStatus = 'error';
				if (!postResult?.error) {
					progressStatus = 'success';
				}
				draft.productDataResult = progressStatus;

				if (product) {
					draft.product = product;
				}

				break;
			}
		}

		return draft;
	}
);
