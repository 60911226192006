import { useState, useEffect } from 'react';
import { Observable, BehaviorSubject, OperatorFunction } from 'rxjs';
import { skip } from 'rxjs/operators';

const useSharedValueMap = <T extends {}, R>(subject: Observable<T>, initialValue: R, op: OperatorFunction<T, R>): R => {
	if ((subject instanceof BehaviorSubject) == false) {
		throw new Error('only BehaviorSubject is supported!');
	}
	let mySub: BehaviorSubject<T> = subject as BehaviorSubject<T>;
	const [value, setState] = useState<R>(initialValue);
	useEffect(() => {
		if (op) {
			//'op' must be used to pass a map like "map(s => s.type === 'test' ? true: false)" that returns R
			const sub = mySub.pipe(skip(1)).pipe(op).subscribe(value => setState(value));
			return () => sub.unsubscribe();
		}
	});
	return value;
};

export default useSharedValueMap;