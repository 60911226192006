import React, { FC, ReactNode } from 'react';
import createConnect from 'productdetail/stockshop/connect';
import { IDealerfinder } from 'dealerfinder/types';

interface OwnProps {
	children: ReactNode;
}

interface StateProps {
	dealerfinder: IDealerfinder;
}

interface DispatchProps {}

type IProps = OwnProps & StateProps & DispatchProps;

const MapContainerLoader: FC<IProps> = (props) => {
	const { children, dealerfinder } = props;
	const { isFetching } = dealerfinder;
	const painting = false;
	const cssDealerContainer = `g g-float dealers-container ${
		isFetching || painting ? 'loading' : ''
	}`;

	return <div className={cssDealerContainer}>{children}</div>;
};

export default createConnect<StateProps, DispatchProps, OwnProps>()(MapContainerLoader);
