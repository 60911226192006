import { StockShopActions } from './types';
import { getResultOrRedirect, errorResult } from 'shared/libs/urlhandling';
import { IStockShop } from 'shared/messages/messages';
import { IHTTPResult } from 'shared/types';

const requestClearStockShop = () => {
	return {
		type: StockShopActions.REQUEST_STOCKSHOP_CLEAR,
	};
};

export const clearStockShop = () => {
	return (dispatch: any, getState: any) => {
		dispatch(requestClearStockShop());
	};
};

const requestFetchStockShop = () => {
	return {
		type: StockShopActions.REQUEST_STOCKSHOP,
	};
};

const receiveFetchStockShop = (postResult: IHTTPResult, data?: IStockShop[]) => {
	return {
		type: StockShopActions.RECEIVE_STOCKSHOP,
		data,
	};
};

export const fetchStockShopData = (sku: string) => {
	return (dispatch: any, getState: any) => {
		dispatch(requestFetchStockShop());

		const { extensions, synchronizerToken } = getState();

		let url = extensions.DealerfinderDispatchUrl;
		let body = 'getStockShopData=getStockShopData';
		body += '&SKU=' + sku;
		body += '&SynchronizerToken=' + synchronizerToken;

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				let data = result?.json ? (result.json?.data['stockShops'] as IStockShop[]) : undefined;
				dispatch(receiveFetchStockShop(result, data));
			})
			.catch((error: any) => dispatch(receiveFetchStockShop(errorResult, undefined)));
	};
};

const requestSetStockShopContext = (sku: string) => {
	return {
		type: StockShopActions.REQUEST_SETSTOCKSHOPCONTEXT,
		sku,
	};
};

export const setStockShopContext = (sku: string) => {
	return (dispatch: any, getState: any) => {
		dispatch(requestSetStockShopContext(sku));
	};
};

const requestUpdateFilter = (filterStock: boolean) => {
	return {
		type: StockShopActions.UPDATE_STOCKSHOP_FILTER,
		filterStock,
	};
};

export const updateFilter = (filterStock: boolean) => {
	return (dispatch: any, getState: any) => {
		dispatch(requestUpdateFilter(filterStock));
	};
};
