import { IHTTPResult } from 'shared/types';
import { getUnwrappedResultOrRedirect, errorResult } from 'shared/libs/urlhandling';
import { IExtensions } from './types';
import { IProduct } from 'shared/messages/messages';

export enum ProductActions {
	REQUEST_PRODUCT_DATA = 'REQUEST_PRODUCT_DATA',
	RECEIVE_PRODUCT_DATA = 'RECEIVE_PRODUCT_DATA',
}

interface RequestProduct {
	type: typeof ProductActions.REQUEST_PRODUCT_DATA;
	sku: string;
	context?: object;
}

const requestProduct = (sku: string, context?: object): RequestProduct => {
	return {
		type: ProductActions.REQUEST_PRODUCT_DATA,
		sku,
		context,
	};
};

interface RecieveProduct {
	type: typeof ProductActions.RECEIVE_PRODUCT_DATA;
	sku: string;
	postResult?: IHTTPResult;
	product?: IProduct;
	context?: object;
}

const receiveProduct = (
	sku: string,
	postResult: IHTTPResult,
	product?: IProduct,
	context?: object
): RecieveProduct => {
	return {
		type: ProductActions.RECEIVE_PRODUCT_DATA,
		sku,
		postResult,
		product,
		context,
	};
};

export type ProductActionTypes = RequestProduct | RecieveProduct;

export const fetchProduct = (
	extensions: IExtensions,
	synchronizerToken: string,
	sku: string,
	dataFlags?: string[],
	context?: object
) => {
	return (dispatch: any, getState: any) => {
		dispatch(requestProduct(sku, context));

		let url = extensions.ProductDataURL;

		let body = '';
		body += 'SKU=' + sku;
		body += '&SynchronizerToken=' + synchronizerToken;

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getUnwrappedResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				let data: IProduct = result?.data as IProduct;
				dispatch(receiveProduct(sku, result, data, context));
			})
			.catch((error: any) => dispatch(receiveProduct(sku, errorResult, undefined, context)));
	};
};

export default { fetchProduct };
