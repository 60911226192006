import React, { FC, useEffect } from 'react';
import createConnect from 'productdetail/stockshop/components/connect';
import { IStockShopState } from 'productdetail/stockshop/types';

import StockShopStore from 'shared/context/stockShopStore';
import useSharedValue from 'shared/hooks/useSharedValue';
import Popup from 'shared/popup';
import StockShopPopup from 'productdetail/stockshop/components/stockShopPopup';
import { ApplicationData } from 'shared/reduceractions/types';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	localizations: object;
	extensions: any;
	synchronizerToken: any;
	stockshop: IStockShopState;
}

interface DispatchProps {
	setStockShopContext: Function;
	fetchApplicationData: Function;
	fetchProduct: Function;
	fetchStockShopData: Function;
	getStores: Function;
	clearStockShop: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;
const StockShopContainer: FC<IProps> = (props) => {
	const { reactId } = props;
	const { extensions, stockshop } = props;
	const {
		setStockShopContext,
		fetchApplicationData,
		fetchProduct,
		fetchStockShopData,
		getStores,
		clearStockShop,
	} = props;
	const { initial, localizations } = stockshop;
	const stockShopSubject = useSharedValue(StockShopStore.subject);

	const showPopup = stockShopSubject?.sku && stockShopSubject.sku !== '' ? true : false;

	//transfer state over to store

	useEffect(() => {
		if (showPopup) {
			setStockShopContext(stockShopSubject.sku);
		} else {
			setStockShopContext('');
		}
	}, [stockShopSubject]);

	//fetch application data

	useEffect(() => {
		if (initial && stockshop?.sku) {
			let url = extensions.GetApplicationURL;
			fetchApplicationData(url, ApplicationData.STOCKSHOP);
		}
	}, [initial, stockshop?.sku]);

	//popup stuff

	const handleClosePopup = () => {
		clearStockShop();
		StockShopStore.clearSubject();
	};

	return (
		<Popup
			title={localizations['product.stockshop.title']}
			popupClass={'stockshop'}
			closePopupHandler={handleClosePopup}
			showPopup={showPopup}
		>
			<StockShopPopup reactId={reactId} />
		</Popup>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(StockShopContainer);
