import { Observable, BehaviorSubject } from 'rxjs';
import IStore from 'dealerfinder/objects/Store';
import useSharedValue from 'shared/hooks/useSharedValue';
import useSharedValueMap from 'shared/hooks/useSharedValueMap';
import { map } from 'rxjs/operators';

interface ISelectedSubject {
	dealer: IStore | null;
}

interface IFilterSubject {
	elite?: boolean;
}

class DealerStore {
	public readonly selectedSubject: Observable<ISelectedSubject>;
	public readonly filterSubject: Observable<IFilterSubject>;
	public readonly paintingSubject: Observable<boolean>;

	public constructor() {
		this.selectedSubject = new BehaviorSubject<ISelectedSubject>({ dealer: null });
		this.filterSubject = new BehaviorSubject<IFilterSubject>({ elite: true });
		this.paintingSubject = new BehaviorSubject<boolean>(false);
	}

	/* selected dealer */

	public getSelectedDealer(): IStore | null {
		const dealer = useSharedValue(this.selectedSubject);
		return dealer?.dealer ?? null;
	}

	public getIsSelectedDealer(dealer: IStore): boolean {
		const dealerSubjectMap = map((value: ISelectedSubject) =>
			value?.dealer?.storeID === dealer.storeID ? true : false
		);
		const isSelected = useSharedValueMap(this.selectedSubject, false, dealerSubjectMap);
		return isSelected;
	}

	private getSelectedSubject(): BehaviorSubject<ISelectedSubject> {
		return this.selectedSubject as BehaviorSubject<ISelectedSubject>;
	}

	public updateSelectedDealer(dealer: IStore) {
		this.getSelectedSubject().next({ dealer });
	}

	public clearSelectedDealer() {
		this.getSelectedSubject().next({ dealer: null });
	}

	/* dealer filter(s) */

	private getFilterSubject(): BehaviorSubject<IFilterSubject> {
		return this.filterSubject as BehaviorSubject<IFilterSubject>;
	}

	public getFilters(): IFilterSubject {
		const filters = useSharedValue(this.filterSubject);
		return filters;
	}

	public updateFilterElite(elite: boolean) {
		if (elite === true) {
			this.getFilterSubject().next({ elite });
		} else {
			this.getFilterSubject().next({});
		}
	}

	public getIsVisible(dealer: IStore): boolean {
		const filters = this.getFilters();
		const isVisible = filters.elite === undefined || filters.elite === dealer.elite ? true : false;
		return isVisible;
	}

	/* painting status */

	public setPaintingStarted() {
		this.getPaintingSubject().next(true);
	}

	public setPaintingCompleted() {
		this.getPaintingSubject().next(false);
	}

	private getPaintingSubject(): BehaviorSubject<boolean> {
		return this.paintingSubject as BehaviorSubject<boolean>;
	}

	public getIsPainting(): boolean {
		const isPainting = useSharedValue(this.paintingSubject);
		return isPainting;
	}
}

const DealerStoreInstance = new DealerStore();
export default DealerStoreInstance as DealerStore;
