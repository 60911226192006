import { IHTTPResult, IProgressResult, ProgressStatus } from 'shared/types';
import { getResultOrRedirect, errorResult } from 'shared/libs/urlhandling';
import { IApplicationData } from './types';

export enum ApplicationActions {
	REQUEST_APPLICATION_DATA = 'REQUEST_APPLICATION_DATA',
	RECEIVE_APPLICATION_DATA = 'RECEIVE_APPLICATION_DATA',
}

interface RequestApplicationData {
	type: typeof ApplicationActions.REQUEST_APPLICATION_DATA;
}

const requestApplicationData = (): RequestApplicationData => {
	return {
		type: ApplicationActions.REQUEST_APPLICATION_DATA,
	};
};

interface RecieveApplicationData {
	type: typeof ApplicationActions.RECEIVE_APPLICATION_DATA;
	result?: IHTTPResult;
	data?: IApplicationData;
}

const receiveApplicationData = (
	result?: IHTTPResult,
	data?: IApplicationData
): RecieveApplicationData => {
	return {
		type: ApplicationActions.RECEIVE_APPLICATION_DATA,
		result,
		data,
	};
};

export type ApplicationActionTypes = RequestApplicationData | RecieveApplicationData;

export const fetchApplicationData = (url: string, application: string) => {
	return (dispatch: any, getState: any) => {
		dispatch(requestApplicationData());

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}?${application}=${application}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				let data: IApplicationData | undefined =
					(result.json?.data as IApplicationData) ?? undefined;
				dispatch(receiveApplicationData(result, data));
			})
			.catch((error: any) => dispatch(receiveApplicationData(errorResult, undefined)));
	};
};

export default { fetchApplicationData };
