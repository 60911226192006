import React, { FC } from 'react';

const Loading: FC = () => {
	return (
		<div className='overlay-loader'>
			<i className='icon-kruitbosch-logo'></i>
			<div className='saving'>
				<span>.</span>
				<span>.</span>
				<span>.</span>
			</div>
		</div>
	);
};

export default Loading;
