import React, { ReactNode } from 'react';
import UndefinedError from 'shared//general/error/undefinedError';

interface IProps {
	children: ReactNode;
	onError?: ReactNode;
	showError?: boolean;
	showNonBreakingSpace?: boolean;
	errorClass?: string;
}

interface IState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidUpdate = (prevProps: IProps, prevState: IState): any => {
		if (prevState.hasError && this.state.hasError && prevProps.children != this.props.children) {
			this.onTryAgain();
		}
	};

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
	}

	onTryAgain() {
		this.setState({ hasError: false });
	}

	render() {
		if (this.state.hasError) {
			if (this.props.onError) {
				return this.props.onError;
			}
			return (
				<UndefinedError
					showError={this.props.showError}
					showNonBreakingSpace={this.props.showNonBreakingSpace}
					errorClass={this.props.errorClass}
				/>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
