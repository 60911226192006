import { RECEIVE_UPDATECOMPAREPRODUCT } from 'productlister/actions';
import { CompareBarActions } from 'shared/general/comparebar/types';
import { PROGRESS_RESULT } from './../types';

import produce from 'immer';
import { ICompareBarState } from './types';

const initialState: ICompareBarState = {
	isFetching: false,
	waiting: false,
	outdated: false,
	success: false,
	numberOfItemsInCompare: 0,
	data: undefined,
	status: PROGRESS_RESULT.NONE,
	maxReached: false,
	reactId: undefined,
};

export function compareBarReducer(reactId: string) {
	const componentInitialState: ICompareBarState = Object.assign({}, initialState, {
		reactId: reactId,
	});
	const reducer = (state = componentInitialState, action): ICompareBarState => {
		return compareBar(state, action);
	};
	return reducer;
}

const compareBar = produce(
	(draft = initialState, action): ICompareBarState => {
		if (!draft) {
			return initialState;
		}
		switch (action.type) {
			case CompareBarActions.REQUEST_COMPARE: {
				draft.waiting = true;
				break;
			}

			case CompareBarActions.RECEIVE_COMPARE: {
				const { data, status } = action;

				if (data) {
					draft.data = data;
					draft.waiting = false;
					draft.outdated = false;
					draft.numberOfItemsInCompare = data.data ? data.data.length : 0;
					draft.status = draft.status === PROGRESS_RESULT.WAITING ? status : PROGRESS_RESULT.NONE;
				}

				break;
			}

			case RECEIVE_UPDATECOMPAREPRODUCT: {
				const { data } = action;

				if (data) {
					draft.outdated = true;
					draft.numberOfItemsInCompare = data.data ? data.data.length : 0;
					draft.status = PROGRESS_RESULT.WAITING;
					draft.success = data.success;
					draft.maxReached = data.errorMessages
						? data.errorMessages.some((c) => c == 'MAX_REACHED')
						: false;
				}

				break;
			}
		}

		return draft;
	}
);
