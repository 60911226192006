import services from 'services/gatagmanager';

export function pushUpdateToCompare(sku, type, compare) {
	if (compare) {
		pushAddToCompare(sku, type);
	} else {
		pushRemoveToCompare(sku, type);
	}
}

function pushAddToCompare(sku, type) {
	let data = {
		addProduct: sku,
		pageType: type,
		event: 'addProductComparison',
	};

	services.gatagmanager.push(data);
}

function pushRemoveToCompare(sku, type) {
	let data = {
		removeProduct: sku,
		pageType: type,
		event: 'removeProductComparison',
	};

	services.gatagmanager.push(data);
}

export function pushCompareProductViews(skus) {
	let data = {
		comparisonProductsRange: skus,
		event: 'pageviewProductComparison',
	};

	services.gatagmanager.push(data);
}

export function pushShowCompareDifferences(skus) {
	let data = {
		comparisonProductsRange: skus,
		event: 'showDifferenceProductComparison',
	};

	services.gatagmanager.push(data);
}

export function pushRemoveFromCompare(sku, originalSkus) {
	let data = {
		removeProduct: sku,
		comparisonProductsRange: originalSkus,
		event: 'removeProductComparison',
	};

	services.gatagmanager.push(data);
}

export function pushEmailCompare(skus) {
	let data = {
		comparisonProductsRange: skus,
		event: 'saveProductComparison',
	};

	services.gatagmanager.push(data);
}

function getPrice(product) {
	if (product.prices) {
		var price = product.prices.find((price) => price.name === 'SalePrice');
		if (!price) {
			price = product.prices.find((price) => price.name === 'ListPrice');
		}
		if (price) {
			return parseFloat(price.whole + '.' + price.fraction);
		}
	}

	return null;
}

function getVariant(product) {
	var variant = null;

	if (product.variantGroups) {
		for (
			var variantGroupIndex = 0;
			variantGroupIndex < product.variantGroups.length;
			variantGroupIndex++
		) {
			var variantGroup = product.variantGroups[variantGroupIndex];

			for (
				var variantIndex = 0;
				variantIndex < variantGroup.productVariants.length;
				variantIndex++
			) {
				var productVariant = variantGroup.productVariants[variantIndex];
				if (productVariant.selected) {
					variant = productVariant.name;
					break;
				}
			}

			if (variant) {
				break;
			}
		}
	} else if (product.variant) {
		variant = product.variant;
	}

	return variant;
}

function getImpression(product, extra) {
	let price = getPrice(product);
	let variant = getVariant(product);
	var brand = null;
	if (product.brand) {
		brand = product.brand;
	}
	var defaultCategory = null;
	if (product.defaultCategory) {
		defaultCategory = product.defaultCategory;
	}
	let product_extra = Object.assign({}, extra);
	if (product.quantity) {
		//console.log('getImpression found quantity', product, extra);
		product_extra.quantity = product.quantity;
	}
	let impression = {
		name: product.name,
		id: product.sku,
		price: price,
		brand: brand,
		category: defaultCategory,
		variant: variant,
		...product_extra,
	};

	return impression;
}

function getImpressions(products, setPosition, extra) {
	let impressions = [];
	let position = 1;

	for (var i = 0; i < products.length; i++) {
		let product = products[i];
		let product_extra = Object.assign({}, extra);
		if (setPosition === true) {
			product_extra.position = position;
		}
		let impression = getImpression(product, product_extra);
		impressions.push(impression);
		position++;
	}

	return impressions;
}

export function pushProductImpressions(products, list) {
	let extra = { list: list };
	let impressions = getImpressions(products, true, extra);
	if (impressions.length === 0) {
		return;
	}

	var data = {
		event: 'productImpression',
		ecommerce: {
			currencyCode: 'EUR',
			impressions: impressions,
		},
	};

	services.gatagmanager.push(data);
}

export function pushProductDetail(products) {
	let impressions = getImpressions(products, false);
	if (impressions.length === 0) {
		return;
	}

	var data = {
		event: 'productDetailView',
		ecommerce: {
			detail: {
				products: impressions,
			},
		},
	};

	services.gatagmanager.push(data);
}

export function pushAddToBasket(products) {
	let impressions = getImpressions(products, false);
	if (impressions.length === 0) {
		return;
	}
	impressions.forEach((element) => {
		element.quantity = 1;
	});
	var data = {
		event: 'addToCart',
		ecommerce: {
			currencyCode: 'EUR',
			add: {
				products: impressions,
			},
		},
	};

	services.gatagmanager.push(data);
}

export function pushProductClick(product, list, position) {
	let extra = { position: position };
	let impression = getImpression(product, extra);
	var impressions = [impression];
	var data = {
		event: 'productClick',
		ecommerce: {
			click: {
				actionField: {
					list: list,
				},
				products: impressions,
			},
		},
	};
	services.gatagmanager.push(data);
}

export function pushCheckoutStep(products, step, options) {
	let impressions = getImpressions(products, false);
	if (impressions.length === 0) {
		return;
	}

	var data = {
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: {
					step: step,
					...options,
				},
				products: impressions,
			},
		},
	};
	services.gatagmanager.push(data);
}

export function pushTransaction(products, orderID, basketTotalExcl, tax, shipping) {
	const getPriceAmount = (price) => {
		if (!price) {
			return null;
		}
		return parseFloat(price.whole + '.' + price.fraction);
	};
	let options = {
		id: orderID,
		affiliation: 'order',
		revenue: getPriceAmount(basketTotalExcl),
		tax: getPriceAmount(tax),
		shipping: getPriceAmount(shipping),
	};

	let impressions = getImpressions(products, false);
	if (impressions.length === 0) {
		return;
	}

	var data = {
		event: 'transactionSend',
		ecommerce: {
			purchase: {
				actionField: {
					...options,
				},
				products: impressions,
			},
		},
	};
	services.gatagmanager.push(data);
}

export function pushDealerfinder(country, postalCityInput, isUserLocation, source, gtmFilter) {
	/*
	 * values used for source:
	 * "dealerfinder"
	 * "checkout"
	 * "storestock"
	 */
	const formName = (source ?? '') === '' ? 'dealerfinder' : source;

	/*
	 * values for gtmFilter that were used in the past:
	 * "elite|"
	 * "ebike|"
	 * "accessoire|"
	 * "none"
	 */
	const formFilter = (gtmFilter ?? '') === '' ? 'none' : gtmFilter;

	var data = {
		formName: formName, //Fill variable with known formName
		formCountry: country, //Fill with known region (i.e. NL, BE or DE)
		formLocation: postalCityInput, //Fill with entered user location (i.e. Zwolle)
		formFilter: formFilter, //gekozen filter met | Anders 'none'
		formUserLocation: isUserLocation, //true or false
		event: 'formSubmission',
	};
	services.gatagmanager.push(data);
}
