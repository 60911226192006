import { Observable, BehaviorSubject } from 'rxjs';

export interface IConfiguration {}

export interface IStockShopSubject {
	sku?: string;
	configuration?: IConfiguration;
}

class StockShopStore {
	public readonly subject: Observable<IStockShopSubject>;

	public constructor() {
		this.subject = new BehaviorSubject<IStockShopSubject>({});
	}

	private getSubject(): BehaviorSubject<IStockShopSubject> {
		return this.subject as BehaviorSubject<IStockShopSubject>;
	}

	public updateSubject(sku: string, configuration?: IConfiguration) {
		this.getSubject().next({ sku, configuration });
	}

	public clearSubject() {
		this.getSubject().next({ sku: undefined, configuration: undefined });
	}
}

const ProductDetailStoreInstance = new StockShopStore();
export default ProductDetailStoreInstance as StockShopStore;
