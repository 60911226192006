import React, { FC, useEffect } from 'react';
import createConnect from 'productdetail/stockshop/connect';
import { IStockShopState } from 'productdetail/stockshop/types';
import {
	IDealerfinder,
	getDefaultPosition,
} from 'dealerfinder/types';
import MapContainer from 'productdetail/stockshop/map/MapContainer';
import Loading from 'shared/components/loading';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	localizations: object;
	extensions: any;
	stockshop: IStockShopState;
	dealerfinder: IDealerfinder;
}

interface DispatchProps {
	setStockShopContext: Function;
	fetchApplicationData: Function;
	fetchProduct: Function;
	fetchStockShopData: Function;
	getStores: Function;
	onRenderCompleted: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;
const StockShopPopup: FC<IProps> = (props) => {
	const { stockshop, dealerfinder } = props;
	const {
		setStockShopContext,
		fetchApplicationData,
		fetchProduct,
		fetchStockShopData,
		getStores,
		onRenderCompleted,
	} = props;
	const { localizations, extensions } = stockshop;
	const { initial, product, productDataResult, stockShops, stockShopsResult } = stockshop;
	const { stores, isDistanceCalculated, locationUser } = dealerfinder;

	// TODO: add icon1/icon2/iconCluster to extensions
	// const icon1 = extensions['icon1'];
	// const icon2 = extensions['icon2'];
	// const iconCluster = extensions['iconCluster'];
	const icon1 =
		'/INTERSHOP/static/WFS/kruitbosch-cortina_nl-Site/-/-/nl_NL/images/marker-dealer.png';
	const icon2 =
		'/INTERSHOP/static/WFS/kruitbosch-cortina_nl-Site/-/-/nl_NL/images/marker-dealer-brandspot.png';
	const iconCluster = '/INTERSHOP/static/WFS/kruitbosch-cortina_nl-Site/-/-/nl_NL/images/m';
	const countryCode = extensions['CountryCode'] === 'US' ? 'NL' : extensions['CountryCode'];
	const language = extensions['Language'];
	const defaultPosition = getDefaultPosition(countryCode);

	//fetch product data

	useEffect(() => {
		if (!initial && stockshop?.sku) {
			const { extensions, synchronizerToken } = stockshop;
			let dataFlags = [];
			let context = {};
			fetchProduct(extensions, synchronizerToken, stockshop?.sku, dataFlags, context);
		}
	}, [initial, stockshop?.sku]);

	//fetch store data

	useEffect(() => {
		if (dealerfinder.mapReady) {
			// const defaultLocation = new google.maps.LatLng({
			// 	lat: defaultPosition.lat,
			// 	lng: defaultPosition.lng,
			// });
			if (stores === undefined) {
				getStores(defaultPosition, countryCode);
			}
		}
	}, [dealerfinder.mapReady]);

	//fetch store stock data

	useEffect(() => {
		if (stores && stores.length && stockShopsResult === undefined) {
			onRenderCompleted();
			fetchStockShopData(stockshop?.sku);
		}
	}, [stores]);

	const isLoading = stockShopsResult !== 'success';

	return (
		<div>
			<p className='mb-2x'>{localizations['product.stockshop.description']}</p>
			<div className='stockshop-modal-inner'>
				{isLoading && <Loading />}
				{/* NOTE: the countryCode/language have to be set correctly on the FIRST render, this affects the behaviour of all future search requests */}
				{!initial && (
					<MapContainer
						icon1={icon1}
						icon2={icon2}
						iconCluster={iconCluster}
						countryCode={countryCode}
						language={language}
						myStockshop={stockshop}
					/>
				)}
				<div className='message'>{localizations['product.stockshop.warning']}</div>
			</div>
		</div>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(StockShopPopup);
