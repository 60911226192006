import React, { useState, FC, useEffect, useRef, useCallback, Fragment } from 'react';
import createConnect from 'productdetail/stockshop/connect';
import { IDealerfinder } from 'dealerfinder/types';
import DealerInformation from 'productdetail/stockshop/map/DealerInformation';
import { IStockShopState } from '../types';
import IStore from 'dealerfinder/objects/Store';

interface OwnProps {
	myStockshop: IStockShopState;
}

interface StateProps {
	dealerfinder: IDealerfinder;
	history: any;
}

interface DispatchProps {
	getStores;
	setLocation;
}

type IProps = OwnProps & StateProps & DispatchProps;

const DealerList: FC<IProps> = (props) => {
	const { myStockshop } = props;
	const { localizations, extensions } = myStockshop;
	const { dealerfinder } = props;
	const { stores } = dealerfinder;
	const { stockShops, filterStock } = myStockshop;
	let hiddenStores = 0;



	return (
		<>
			<div className='dealers' id='dealerdata'>
				<div className='dealers-inner list' id='listdata'>
					{stores &&
						<>
							{stores.map((store) => {
								const stockShop = stockShops?.find((s) => {
									return s.dealerID === store.storeID;
								});
								const hide = filterStock && (!stockShop || stockShop.quantity < 1);
								if (hide) {
									hiddenStores++;
								}
								const dealerCSS = `dealersearch ${hide ? 'hide' : ''}`;
								const membershipCSS = ` ${store.elite ? 'elite' : ''}`;
								return (
									<div className={dealerCSS + membershipCSS} key={store.storeID}>
										<DealerInformation
											extensions={extensions}
											localizations={localizations}
											store={store}
											stockShop={stockShop}
										/>
									</div>
								);
							})
							}
							{hiddenStores >= stores.length &&
								<div className='notify-negative'>{localizations['product.stockshop.list.empty']}</div>
							}
						</>
					}
				</div>
			</div>
		</>
	);
};

export default createConnect<StateProps, DispatchProps, OwnProps>()(DealerList);
