export enum ApplicationData {
	STOCKSHOP = 'stockshop',
}

export interface IApplicationData {
	localizations: object;
	extensions: IExtensions;
	synchronizerToken: string;
	applicationData: object;
}

export interface IExtensions extends Object {
	ProductDataURL: string;
}
