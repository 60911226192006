import { IHTTPResult } from 'shared/types';
import IStore from 'dealerfinder/objects/Store';

export interface IDealerfinder {
	isFetching: boolean;
	stores?: IStore[];
	storesData?: IStore[];
	storesResult?: IHTTPResult;
	isDistanceCalculated: boolean;
	location?: google.maps.LatLngLiteral;
	locationUser?: google.maps.LatLngLiteral;
	mapReady: boolean;
	mapSelection: IMapSelection | undefined;
}

export interface IMapSelection {
	date: Date;
	dealer: IStore | undefined;
}

export const DealerFinderConstants = {
	centerlatNL: '52.1734596',
	centerlngNL: '5.6339202',
	centerlatDE: '51.0899771',
	centerlngDE: '10.2104881',
	centerlatBE: '50.4921024',
	centerlngBE: '4.6218456',
	defaultZoom: 14,
	locationMinimumZoom: 15,
	defaultZoomNL: 7.5,
	defaultZoomBE: 7.6,
	defaultZoomDE: 6.4,
};

export const DealerFinderDefaultPositions = {
	defaultPosNL: {
		lat: parseFloat(DealerFinderConstants.centerlatNL),
		lng: parseFloat(DealerFinderConstants.centerlngNL),
	},
	defaultPosDE: {
		lat: parseFloat(DealerFinderConstants.centerlatDE),
		lng: parseFloat(DealerFinderConstants.centerlngDE),
	},
	defaultPosBE: {
		lat: parseFloat(DealerFinderConstants.centerlatBE),
		lng: parseFloat(DealerFinderConstants.centerlngBE),
	},
};

export const getDefaultPosition = (countryCode: String) => {
	switch (countryCode) {
		case 'NL':
			return DealerFinderDefaultPositions.defaultPosNL;
		case 'DE':
			return DealerFinderDefaultPositions.defaultPosDE;
		case 'BE':
			return DealerFinderDefaultPositions.defaultPosBE;
		case 'US':
			//no point to focus on the US/UK
			return DealerFinderDefaultPositions.defaultPosNL;
		default:
			return DealerFinderDefaultPositions.defaultPosNL;
	}
};

export const getDefaultZoom = (countryCode: String) => {
	switch (countryCode) {
		case 'NL':
			return DealerFinderConstants.defaultZoomNL;
		case 'DE':
			return DealerFinderConstants.defaultZoomDE;
		case 'BE':
			return DealerFinderConstants.defaultZoomBE;
		case 'US':
			//no point to focus on the US/UK
			return DealerFinderConstants.defaultZoomNL;
		default:
			return DealerFinderConstants.defaultZoomNL;
	}
};

export interface History {
	push(path: string, state?: any): void;
	goBack(): any;
}

export enum DealerfinderActions {
	SELECT_DEALER = 'SELECT_DEALER',
	CLEAR_MAP = 'CLEAR_MAP',
	REQUEST_STORES = 'REQUEST_STORES',
	RECEIVE_STORES = 'RECEIVE_STORES',
	RENDER_COMPLETED = 'RENDER_COMPLETED',
	REQUEST_CALCULATE_DISTANCE = 'REQUEST_CALCULATE_DISTANCE',
	RECEIVE_CALCULATE_DISTANCE = 'RECEIVE_CALCULATE_DISTANCE',
	REQUEST_SET_LOCATION = 'REQUEST_SET_LOCATION',
	MAP_READY = 'MAP_READY',
}

interface RequestStoresAction {
	type: typeof DealerfinderActions.REQUEST_STORES;
	isFetching: boolean;
}

interface ReceiveStoresAction {
	type: typeof DealerfinderActions.RECEIVE_STORES;
	position?: google.maps.LatLng;
	stores: IStore[];
	result: IHTTPResult;
}

interface RenderCompletedAction {
	type: typeof DealerfinderActions.RENDER_COMPLETED;
}

interface RequestCalculateDistanceAction {
	type: typeof DealerfinderActions.REQUEST_CALCULATE_DISTANCE;
}

interface ReceiveCalculateDistanceAction {
	type: typeof DealerfinderActions.RECEIVE_CALCULATE_DISTANCE;
	position: google.maps.LatLng;
}

interface RequestSetLocation {
	type: typeof DealerfinderActions.REQUEST_SET_LOCATION;
	position?: google.maps.LatLng;
	isUserLocation?: boolean;
}

interface RequestSetMapReady {
	type: typeof DealerfinderActions.MAP_READY;
}

interface RequestClearMap {
	type: typeof DealerfinderActions.CLEAR_MAP;
}

interface RequestSelectDealer {
	type: typeof DealerfinderActions.SELECT_DEALER;
	dealer: IStore | undefined;
}

export type DealerfinderActionsTypes =
	| RequestStoresAction
	| ReceiveStoresAction
	| RenderCompletedAction
	| RequestCalculateDistanceAction
	| ReceiveCalculateDistanceAction
	| RequestSetLocation
	| RequestSetMapReady
	| RequestClearMap
	| RequestSelectDealer;
