import { GeneralActions } from 'general/actions';
import { stockShopReducer } from 'productdetail/stockshop/reducers';

function localizations(state = {}, action) {
	switch (action.type) {
		case GeneralActions.ADD_LOCALIZATION:
			const { localizations } = action;
			let newState = Object.assign({}, state, localizations);
			return newState;
		default:
			//linter needs default entry
			break;
	}
	return state;
}

function synchronizer(state = {}, action) {
	switch (action.type) {
		case GeneralActions.ADD_SYNCHRONIZERTOKEN:
			const { name, synchronizertoken } = action;
			let newState = Object.assign({}, state);
			newState[name] = synchronizertoken;
			return newState;
		default:
			//linter needs default entry
			break;
	}
	return state;
}

function data(state = {}, action) {
	switch (action.type) {
		case GeneralActions.ADD_DATA:
			const { name, data } = action;
			let newState = Object.assign({}, state);
			newState[name] = data;
			return newState;
		default:
			//linter needs default entry
			break;
	}
	return state;
}

function extensions(state = {}, action) {
	switch (action.type) {
		case GeneralActions.ADD_EXTENSIONS:
			const { extensions } = action;
			let newState = Object.assign({}, state, extensions);
			return newState;
		default:
			//linter needs default entry
			break;
	}
	return state;
}

export function createGeneralReducers() {
	return {
		localizations,
		synchronizer,
		extensions,
		data,
		stockshop: stockShopReducer,
		general: () => window['__REACT_GENERAL__'],
	};
}
