import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as compareActions from 'shared/general/comparebar/actions';

function createConnect<StateProps, DispatchProps, OwnProps>() {
	const mapStateToProps = (state: any, ownProps: any): any => {
		let initialState = {
			localizations: state.localizations,
			extensions: state.extensions,
			general: state.general,
			comparebar: state.comparebar,
			data: undefined,
		};

		if (ownProps.reactId) {
			initialState = Object.assign(initialState, {
				comparebar: state[ownProps.reactId],
			});

			if (state?.synchronizer) {
				initialState = Object.assign(initialState, {
					synchronizerToken: state.synchronizer[ownProps.reactId],
				});
			}

			if (state?.data) {
				//data-react-data attributes are gathered in a single object for all components, map the state back to this specific component
				initialState = Object.assign(initialState, {
					data: state.data[ownProps.reactId],
				});
			}
		}

		return initialState;
	};

	const mapDispatchProps = (dispatch: any): any => {
		const actions = {
			...compareActions,
		};
		return bindActionCreators(actions, dispatch);
	};

	const connectContext = connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchProps
	);
	return connectContext;
}

export default createConnect;
