import configureStore from 'general/configureStore';
import { createGeneralComponents } from 'shared/general';

function run() {
	//check if there is a master app if not run  general components standalone;
	//console.log('general run', window.__MASTER_REACT_APP__);

	if (!window.__MASTER_REACT_APP__) {
		const initialState = {};

		const store = configureStore(initialState);
		createGeneralComponents(store);
	}
	const params = new URLSearchParams(window.location.search);

	if (params.has('magic')) {
		const [currentStyle, newStyle] = params.get('magic').split(',');
		const stylesheet = document.querySelector(`link[href*="${currentStyle}.min.css"]`);

		const reg = new RegExp(currentStyle + '.min', 'g');
		stylesheet.href = stylesheet.href.replace(reg, newStyle + '.min');
	}
}

export default { run: run };
