import { IProduct, IStockShop } from 'shared/messages/messages';
import { ProgressStatus } from 'shared/types';

export interface IStockShopState {
	reactId: string;
	button: string;
	//application data
	localizations?: any;
	extensions?: any;
	synchronizerToken?: string;
	initial: boolean;
	//product data
	sku?: string;
	productDataResult?: ProgressStatus;
	product?: IProduct;
	//stockshop data
	stockShopsResult?: ProgressStatus;
	stockShops?: IStockShop[];
	filterStock: boolean;
}

export enum StockShopActions {
	REQUEST_STOCKSHOP_CLEAR = 'REQUEST_STOCKSHOP_CLEAR',

	REQUEST_STOCKSHOP = 'REQUEST_STOCKSHOP',
	RECEIVE_STOCKSHOP = 'RECEIVE_STOCKSHOP',

	UPDATE_STOCKSHOP_FILTER = 'UPDATE_STOCKSHOP_FILTER',

	REQUEST_SETSTOCKSHOPCONTEXT = 'REQUEST_SETSTOCKSHOPCONTEXT',
}
