export enum GeneralActions {
	ADD_LOCALIZATION = 'ADD_LOCALIZATION',
	ADD_SYNCHRONIZERTOKEN = 'ADD_SYNCHRONIZERTOKEN',
	ADD_DATA = 'ADD_DATA',
	ADD_EXTENSIONS = 'ADD_EXTENSIONS',
}

interface AddLocalization {
	type: typeof GeneralActions.ADD_LOCALIZATION;
	localizations: object;
}

interface AddSynchronizerToken {
	type: typeof GeneralActions.ADD_SYNCHRONIZERTOKEN;
	name: string;
	synchronizertoken: string;
}

interface AddData {
	type: typeof GeneralActions.ADD_DATA;
	name: string;
	data: object;
}

interface AddExtensions {
	type: typeof GeneralActions.ADD_EXTENSIONS;
	name: string;
	extensions: object;
}

export type GeneralActionTypes = AddLocalization | AddSynchronizerToken | AddData | AddExtensions;

function localizationAction(localizations: object) {
	return {
		type: GeneralActions.ADD_LOCALIZATION,
		localizations,
	};
}

export function addLocalizationsToStore(store: any, localizations: object) {
	store.dispatch(localizationAction(localizations));
}

export function addLocalizations(localizations: object) {
	return (dispatch: any, getState: any) => {
		dispatch(localizationAction(localizations));
	};
}

function synchronizertokenAction(name: string, synchronizertoken: string) {
	return {
		type: GeneralActions.ADD_SYNCHRONIZERTOKEN,
		name,
		synchronizertoken,
	};
}

export function addSynchronizerTokenToStore(store: any, name: string, synchronizertoken: string) {
	store.dispatch(synchronizertokenAction(name, synchronizertoken));
}

export function addSynchronizerToken(name: string, synchronizertoken: string) {
	return (dispatch: any, getState: any) => {
		dispatch(synchronizertokenAction(name, synchronizertoken));
	};
}

function dataAction(name: string, data: object) {
	return {
		type: GeneralActions.ADD_DATA,
		name,
		data,
	};
}

export function addDataToStore(store: any, name: string, data: object) {
	store.dispatch(dataAction(name, data));
}

export function addData(name: string, data: object) {
	return (dispatch: any, getState: any) => {
		dispatch(dataAction(name, data));
	};
}

function extensionsAction(extensions: object) {
	return {
		type: GeneralActions.ADD_EXTENSIONS,
		extensions,
	};
}

export function addExtensionsToStore(store: any, extensions: object) {
	store.dispatch(extensionsAction(extensions));
}

export function addExtensions(extensions: object) {
	return (dispatch: any, getState: any) => {
		dispatch(extensionsAction(extensions));
	};
}
