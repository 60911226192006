import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from './errorBoundary';
import {
	addLocalizationsToStore,
	addSynchronizerTokenToStore,
	addDataToStore,
	addExtensionsToStore,
} from 'general/actions';

import CompareBar from 'shared/general/applications/CompareBar';
import { compareBarReducer } from 'shared/general/comparebar/reducers';

import StockShop from 'productdetail/stockshop';
import { stockShopReducer } from 'productdetail/stockshop/reducers';
import { reduceDealerfinder } from 'dealerfinder/reducers';

const genericComponentMap = {
	'./CompareBar': CompareBar,
	'./StockShop': StockShop,
};

const injectReducer = (dataset, store, reactGeneral, reactId) => {
	switch (reactGeneral) {
		case './CompareBar':
			store.injectReducer(reactId, compareBarReducer(reactId));
			store.injectReducer('dealerfinder', reduceDealerfinder);
			break;
		case './StockShop':
			const { button } = dataset;
			store.injectReducer(reactId, stockShopReducer(reactId, button));
			break;
		default:
	}
};

const renderComponent = (store, element) => {
	const {
		reactGeneral,
		reactId,
		reactUrl,
		reactSynchronizertoken,
		reactLocalizations,
		reactData,
		reactExtensions,
	} = element.dataset;

	let localizations = null;
	let data = null;
	let extensions = null;

	if (reactLocalizations) {
		localizations = JSON.parse(reactLocalizations);
		addLocalizationsToStore(store, localizations);
	}

	if (reactSynchronizertoken) {
		addSynchronizerTokenToStore(store, reactId, reactSynchronizertoken);
	}

	if (reactData) {
		data = JSON.parse(reactData);
		addDataToStore(store, reactId, data);
	}

	if (reactExtensions) {
		extensions = JSON.parse(reactExtensions);
		addExtensionsToStore(store, extensions);
	}

	if (reactId && store.injectReducer) {
		injectReducer(element.dataset, store, reactGeneral, reactId);
	}

	const GenericComponent = genericComponentMap[reactGeneral];
	if (GenericComponent) {
		render(
			<ErrorBoundary>
				<Provider store={store}>
					<GenericComponent url={reactUrl} reactId={reactId} />
				</Provider>
			</ErrorBoundary>,
			document.querySelector(`[data-react-id="${reactId}"]`)
		);
	}
};

export function createGeneralComponents(store) {
	const apps = Array.from(document.querySelectorAll('[data-react-general]'));
	apps.forEach((element) => {
		try {
			renderComponent(store, element);
		} catch (e) {
			console.error('could not render component:', element, e);
		}
	});
}
