var services = services || {};

// Use this service for handling the ga tagmanager
services.gatagmanager = (function() {
	var me = {
		verbose: false,
	};

	me.push = function(data) {
		me.log(data);

		if (window.dataLayer) {
			window.dataLayer.push(data);
		}
	};

	me.log = function(message) {
		if (me.verbose) {
			console.log('me.verbose', message);
		}
	};

	return me;
})();

export default services;
