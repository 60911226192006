import { PROGRESS_RESULT } from '../types';

export interface ICompareBarState {
	isFetching: boolean;
	waiting: boolean;
	outdated: boolean;
	success: boolean;
	numberOfItemsInCompare: number;
	data: any; //not sure what this is
	status: PROGRESS_RESULT;
	maxReached: boolean;
	reactId: string | undefined;
}

export enum CompareBarActions {
	REQUEST_COMPARE = 'REQUEST_COMPARE',
	RECEIVE_COMPARE = 'RECEIVE_COMPARE',
}

interface RequestCompareProductAction {
	type: typeof CompareBarActions.REQUEST_COMPARE;
}

interface ReceiveCompareProductAction {
	type: typeof CompareBarActions.RECEIVE_COMPARE;
	data: { data: string[]; errorMessages: string[]; success: boolean };
	status: any;
}

export type CompareBarActionsTypes = RequestCompareProductAction | ReceiveCompareProductAction;
