import React, { FC } from 'react';
import IStore from 'dealerfinder/objects/Store';

interface IProps {
	localizations: object;
	store: IStore;
	mode: 'dealerfinder' | 'checkoutpickup';
	inStock: boolean;
}

const DealerCard: FC<IProps> = (props) => {
	const { localizations, store, mode, inStock } = props;
	const dataIconKM = { 'data-lease': '1' };
	const distance = store.distance ? parseFloat(store.distance).toFixed(1) : null;
	const cssInStock = inStock ? 'notify-positive-black' : 'notify-negative-black';
	const stockMessage = inStock
		? localizations['dealerfinder.marker.stockshop.instock.text']
		: localizations['dealerfinder.marker.stockshop.notinstock.text'];
	const dataIconStock = inStock ? { 'data-check-positive': '1' } : { 'data-check-negative': '1' };

	return (
		<div className='dealer-card-head'>
			<div className='dealer-name'>
				<h3 className='mb-0 head name'>{store.name}</h3>
				<div className='flex-container align-center dealer-info-wr'>
					<div className={`dealer-stock ${cssInStock}`}>
						<span {...dataIconStock}>{stockMessage}</span>
					</div>
					{store.elite && (
						<div className='dealer-elite'>
							{localizations['dealerfinder.marker.dealer.elite.label']}
						</div>
					)}
				</div>
			</div>
			<div className='dealer-card-distancevertical'>
				<div className='dealer-card-distancevertical-item'>{store.city.toLowerCase()}</div>
				{store.distance && (
					<div className='dealer-card-distancevertical-item'>
						<span {...dataIconKM}>{distance}&nbsp;</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default React.memo(DealerCard);
