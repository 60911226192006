import { useEffect, useState } from 'react';

export enum LocationSource {
	NO_LOCATION,
	GEO_LOCATION,
	SEARCH_LOCATION,
	CUSTOM_LOCATION,
}

export interface ILocation {
	location?: google.maps.LatLng;
	description?: string;
	hasLocation: boolean;
	source: LocationSource;
}

export interface ILocationAPI {
	location: TLocation;
	locationServiceAvailable: boolean;
	getCurrentLocation: () => void;
	searchLocation: (searchTerm: string, countryCode: string) => void;
	setCustomLocation: (location: google.maps.LatLng, description: string) => void;
}

export type TLocation = ILocation | undefined;

export default function useLocation(
	useGeoLocation: boolean,
	initialLocation: google.maps.LatLng | undefined
) {
	const [locationServiceAvailable, setLocationServiceAvailable] = useState(true);
	let initialState: TLocation = undefined;
	if (initialLocation) {
		initialState = {
			location: initialLocation,
			description: undefined,
			hasLocation: false,
			source: LocationSource.NO_LOCATION,
		};
	}
	const [location, setLocation] = useState<TLocation>(initialState);

	useEffect(() => {
		if (!navigator.geolocation) {
			setLocationServiceAvailable(false);
		} else if (useGeoLocation) {
			let watchID = navigator.geolocation.watchPosition(
				(pos) => {
					//success
					navigator.geolocation.clearWatch(watchID);
					const newLocation: TLocation = {
						location: new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude),
						hasLocation: true,
						source: LocationSource.GEO_LOCATION,
					};
					setLocation(newLocation);
				},
				() => {
					//error
					navigator.geolocation.clearWatch(watchID);
					setLocationServiceAvailable(false);
				}
			);
		}
	}, []);

	useEffect(() => {
		if (!locationServiceAvailable && navigator.geolocation) {
			setLocationServiceAvailable(true);
		}
	}, [navigator.geolocation]);

	const getCurrentLocation = () => {
		if (!navigator.geolocation) {
			setLocationServiceAvailable(false);
		} else {
			//dont check for useGeoLocation here. it's intended to enable/disable the INITIAL location check
			//if you really dont want to use geolocation, don't call getCurrentLocation :|
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					//success
					const newLocation: TLocation = {
						location: new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude),
						hasLocation: true,
						source: LocationSource.GEO_LOCATION,
					};
					setLocation(newLocation);
					setLocationServiceAvailable(true);
				},
				() => {
					//error
					setLocationServiceAvailable(false);
				}
			);
		}
	};

	const searchLocation = (searchTerm: string, countryCode: string) => {
		var geocoder = new google.maps.Geocoder(); // Get latitude and longitude from address
		geocoder.geocode({ address: searchTerm }, (results, status) => {
			const unknownLocation: TLocation = {
				//NOTE: Map component does handle the "center" prop transition from "defined" to "undefined" well, so keep location "existing" at all times
				location: location?.location,
				description: undefined,
				hasLocation: false,
				source: LocationSource.NO_LOCATION,
			};
			if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
				let result = results[0];
				let isCountry =
					result.address_components.findIndex(
						(c) => c.types.findIndex((t) => t === 'country') > -1 && c.short_name === countryCode
					) > -1;

				//allow FR+BE results on FR
				if (countryCode === 'FR') {
					isCountry =
						isCountry ||
						result.address_components.findIndex(
							(c) => c.types.findIndex((t) => t === 'country') > -1 && c.short_name === 'BE'
						) > -1;
				}
				//allow BE+FR results on BE
				if (countryCode === 'BE') {
					isCountry =
						isCountry ||
						result.address_components.findIndex(
							(c) => c.types.findIndex((t) => t === 'country') > -1 && c.short_name === 'FR'
						) > -1;
				}

				if (isCountry) {
					const newLocation: TLocation = {
						location: new google.maps.LatLng(
							result.geometry.location.lat(),
							result.geometry.location.lng()
						),
						description: result.formatted_address,
						hasLocation: true,
						source: LocationSource.SEARCH_LOCATION,
					};
					setLocation(newLocation);
				} else {
					setLocation(unknownLocation);
				}
			} else {
				setLocation(unknownLocation);
			}
		});
	};

	const setCustomLocation = (location: google.maps.LatLng, description: string) => {
		const newLocation: TLocation = {
			location: location,
			description: description,
			hasLocation: true,
			source: LocationSource.CUSTOM_LOCATION,
		};
		setLocation(newLocation);
	};

	const locationAPI = {
		location,
		locationServiceAvailable,
		getCurrentLocation,
		searchLocation,
		setCustomLocation,
	};

	return locationAPI;
}
