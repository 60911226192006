/*
Can be used to handle redirects with JSON calls.
    "How to use"
    Replace this:
        fetch(url, {})
        .then(response => response.json())

    With this:
        fetch(url, {})
        .then(getJsonOrRedirect)
*/
export function getJsonOrRedirect(response) {
	if (response.redirected) {
		window.location.href = response.url;
	}

	var promise = response.json();
	return promise.then((json) => {
		if (json.redirected) {
			//go to redirect url
			window.location.href = json.url;
			//return empty data so the current action doen not fail
			const myPromise = new Promise((resolve, reject) => {
				var fakeJSON = { data: null };
				resolve(fakeJSON);
			});
			return myPromise;
		} else {
			//return the server json as if nothing happened
			const myPromise = new Promise((resolve, reject) => {
				resolve(json);
			});
			return myPromise;
		}
	});
}

function getResultFromResponse(response, redirect, iswrapped) {
	let result = {
		json: undefined,
		status: 0,
		error: true,
	};

	const myPromise = new Promise((resolve, reject) => {
		result.status = response.status;
		if (response.status == 200) {
			result.error = false;
			resolve(response);
		} else {
			reject('error: ' + response.status);
		}
	});

	const getJson = (response) => response.json();

	return myPromise
		.then(redirect ? getJsonOrRedirect : getJson)
		.then((json) => {
			if (iswrapped) {
				result.error = !json.success;
				result.json = json;
			} else {
				result.data = json;
			}
			return result;
		})
		.catch((error) => {
			result.error = true;
			//return result no matter what, so an error message can be shown
			return result;
		});
}

/*
Can be used to handle redirects with JSON calls.
Returns commonly used IHTTPResult object from result, will also return on error.
    "How to use"
    Replace this:
        fetch(url, {})
        .then(response => response.json())

    With this:
        fetch(url, {})
        .then(getResultOrRedirect)
        .then(result => dispatch(receiveResult(result)))
*/
export function getResultOrRedirect(response) {
	return getResultFromResponse(response, true, true);
}

export function getUnwrappedResultOrRedirect(response) {
	return getResultFromResponse(response, true, false);
}

/*
Returns commonly used IHTTPResult object from result, will also return on error.
    "How to use"
    Replace this:
        fetch(url, {})
        .then(response => response.json())

    With this:
        fetch(url, {})
        .then(getResult)
        .then(result => dispatch(receiveResult(result)))
*/
export function getResult(response) {
	return getResultFromResponse(response, false, true);
}

/* return IHTTPResult to be used in promise catch clauses, for example: .catch((error: any)=> { dispatch(errorResult) }) */
export const errorResult = {
	status: 0,
	error: true,
};

/**
 * Converts json data to a string that can be used for application/x-www-form-urlencoded forms
 *
 * The values of the json object are url encoded (the keys are not)
 *
 * @param formObject object
 * @return string
 */
export const uriEncodeFormObject = (formObject) =>
	Object.keys(formObject)
		.map((key) => key + '=' + encodeURIComponent(formObject[key]))
		.join('&');
