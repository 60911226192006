import React, { FC } from 'react';

interface IProps {
	showError?: boolean;
	showNonBreakingSpace?: boolean;
	errorClass?: string;
}

/*
 * NOTE: Because this component is used as FALLBACK-content in case of errors it should avoid localization, stateful logic, onclick behaviour, etc.
 * Don't let errors introduce other errors.
 */
const UndefinedError: FC<IProps> = (props) => {
	const dummy = <>{props.showNonBreakingSpace === true ? <span>&nbsp;</span> : <></>}</>;
	const content = (
		<>
			{props.showError === true ? <div className={`head notify-negative mb`}>⚠</div> : <>{dummy}</>}
		</>
	);
	const gridContent = (
		<>
			{props.errorClass ? <div className={`${props.errorClass}`}>{content}</div> : <>{content}</>}
		</>
	);
	return <>{gridContent}</>;
};

export default UndefinedError;
