import MarkerClusterer from '@googlemaps/markerclustererplus';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import DealerStore from 'productdetail/stockshop/map/DealerStore';

class ClusterService {
	private static cluster?: MarkerClusterer;
	private static repaintSubject$: Subject<void> = new Subject<void>();
	private static debug = false;

	constructor(obj) {
		Object.assign(this, obj);
	}

	static clearMarkers() {
		if (!this.cluster) {
			return;
		}
		if (this.debug) {
			console.log('clearMarkers', new Date());
		}
		this.cluster.clearMarkers();
	}

	static addMarker(marker: google.maps.Marker) {
		if (this.debug) {
			console.log('addMarker', new Date());
		}
		if (!this.cluster) {
			return;
		}
		this.cluster.addMarker(marker, true);
		this.repaintSubject$.next();
	}

	static removeMarker(marker: google.maps.Marker) {
		if (this.debug) {
			console.log('removeMarker', new Date());
		}
		if (!this.cluster) {
			return;
		}
		this.cluster.removeMarker(marker, true);
		this.repaintSubject$.next();
	}

	static repaint() {
		if (this.debug) {
			console.log('repaint', new Date());
		}
		if (this.cluster) {
			this.cluster.repaint();
		}
	}

	static initializeCluster(map, options, onRenderCompleted) {
		if (this.debug) {
			console.log('initializeCluster', new Date());
		}
		if (!this.cluster) {
			var cluster = new MarkerClusterer(map, [], options);
			this.cluster = cluster;
			this.cluster.addListener('clusteringend', (mc: MarkerClusterer) => {
				onRenderCompleted();
			});
			this.cluster.repaint();

			const subscription = this.repaintSubject$
				.pipe(
					debounceTime(200) // wait until all markers have been added/removed
				)
				.subscribe({
					next: () => {
						ClusterService.repaint();
						DealerStore.setPaintingCompleted();
					},
					error: (error) => {
						// handle error here
						console.error(error);
					},
					complete: () => {},
				});
		}
	}

	static getMarkers() {
		if (!this.cluster) {
			return [];
		}
		return this.cluster.getMarkers();
	}

	static getMarkersClustered() {
		if (!this.cluster) {
			return [];
		}
		const coordinates: google.maps.LatLngLiteral[] = [];
		const markersClustered = this.cluster.getClusters().map((cl) => cl.getMarkers());
		const markersGrouped = markersClustered.filter((marker) => marker.length > 1);
		markersGrouped.map((markers) =>
			markers.map((marker) => {
				let pos = marker.getPosition();
				if (pos) {
					coordinates.push({
						lat: pos.lat(),
						lng: pos.lng(),
					});
				}
			})
		);
		return coordinates;
	}

	static getClosestMarker(target: google.maps.LatLngLiteral): google.maps.Marker | null {
		if (!this.cluster) {
			return null;
		}

		let minDistance = -1;
		let closestMarker: google.maps.Marker | null = null;
		const targetPosition = new google.maps.LatLng({ lat: target.lat, lng: target.lng });

		let markers = this.getMarkers();
		markers.forEach((marker) => {
			let markerPosition = marker.getPosition();
			if (markerPosition) {
				let distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(
					markerPosition,
					targetPosition
				);
				if (closestMarker == null || distanceMeters < minDistance) {
					minDistance = distanceMeters;
					closestMarker = marker;
				}
			}
		});
		return closestMarker;
	}

	static getMarkerByID(id: string): google.maps.Marker | null {
		if (!this.cluster) {
			return null;
		}
		let markers = this.getMarkers();
		for (let i = 0; i < markers.length; i++) {
			const marker = markers[i];
			const storeID: string = marker['storeID'];
			if (id == storeID) {
				return marker;
			}
		}

		return null;
	}
}

export default ClusterService;
