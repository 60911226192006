import React, { useState, FC, useEffect, useCallback } from 'react';
import GoogleMapReact, {
	Map,
	Marker,
	GoogleApiWrapper,
	GoogleAPI,
	IMarkerProps,
	IInfoWindowProps,
} from 'google-maps-react';
import {
	IDealerfinder,
	DealerFinderConstants,
	getDefaultPosition,
	getDefaultZoom,
} from 'dealerfinder/types';
import IStore from 'dealerfinder/objects/Store';
import createConnect from 'productdetail/stockshop/connect';
import ClusterService from 'productdetail/stockshop/map/ClusterService';
import SearchForm from 'productdetail/stockshop/map/SearchForm';
import DealerList from 'productdetail/stockshop/map/DealerList';
import useLocation, { LocationSource } from 'shared/hooks/useLocation';
import DealerStore from 'productdetail/stockshop/map/DealerStore';
import MapContainerLoader from 'productdetail/stockshop/map/MapContainerLoader';
import usePrevious from 'shared/hooks/usePrevious';
import { IStockShopState } from 'productdetail/stockshop/types';
import StockShopProduct from 'productdetail/stockshop/components/stockShopProduct';
import { pushDealerfinder } from 'shared/libs/gaevents';

interface ISelection {
	selectedMarker?: google.maps.Marker;
	selectedStore?: IStore;
	visible: boolean;
}

interface OwnProps {
	google: GoogleAPI;
	icon1: string;
	icon2: string;
	iconCluster: string;
	countryCode: string;
	language: string;
	myStockshop: IStockShopState;
}

interface StateProps {
	dealerfinder: IDealerfinder;
}

interface DispatchProps {
	getStores;
	calculateDistance;
	onRenderCompleted;
	setLocation;
}

type IProps = OwnProps & StateProps & DispatchProps;

const MapContainer: FC<IProps> = (props) => {
	const {
		dealerfinder,
		icon1,
		icon2,
		iconCluster,
		google,
		countryCode,
		language,
		myStockshop,
	} = props;
	const { localizations, extensions } = myStockshop;
	const { stores, isDistanceCalculated } = dealerfinder;
	const { getStores, calculateDistance, onRenderCompleted, setLocation } = props;
	const defaultPosition = getDefaultPosition(countryCode);
	const defaultZoom = getDefaultZoom(countryCode);
	const defaultPositionLatLng = new google.maps.LatLng({
		lat: defaultPosition.lat,
		lng: defaultPosition.lng,
	});

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/dist/index.min.js';
		script.async = true;
		document.body.appendChild(script);
	}, []);

	const [map, setMap] = useState<google.maps.Map>();

	const {
		location,
		locationServiceAvailable,
		getCurrentLocation,
		searchLocation,
		setCustomLocation,
	} = useLocation(true, defaultPositionLatLng);

	const prevLocation = usePrevious(location?.location);
	const currentLocation =
		prevLocation !== location?.location ? location?.location : map?.getCenter() ?? undefined;
	const currentLocationDefined = !currentLocation
		? defaultPositionLatLng
		: isNaN(currentLocation.lat()) || isNaN(currentLocation.lng())
		? defaultPositionLatLng
		: currentLocation;
	const mapLocation: google.maps.LatLngLiteral | undefined = currentLocationDefined
		? { lat: currentLocationDefined?.lat(), lng: currentLocationDefined?.lng() }
		: defaultPosition;
	const userLocation: google.maps.LatLngLiteral | undefined = location?.location
		? { lat: location.location?.lat(), lng: location.location?.lng() }
		: undefined;

	const [selected, setSelected] = useState<ISelection>({ visible: false });
	const [zoom, setZoom] = useState(defaultZoom);
	const [hasCountryZoom, setCountryZoom] = useState(true);

	const hasStores = stores && stores.length;

	useEffect(() => {
		if (!hasStores) {
			getStores(defaultPositionLatLng, countryCode);
		}
	}, []);

	useEffect(() => {
		if (hasStores && !isDistanceCalculated) {
			if (
				location?.hasLocation &&
				userLocation &&
				location.source === LocationSource.GEO_LOCATION
			) {
				const userPosition = new google.maps.LatLng({
					lat: userLocation.lat,
					lng: userLocation.lng,
				});
				calculateDistance(userPosition);
			}
		}
		if (hasStores) {
			//simulate that all the markers have finished rendering (because they are removed from MapContainer)
			onRenderCompleted();
		}
	}, [stores]);

	useEffect(() => {
		if (location?.hasLocation && userLocation) {
			if (hasCountryZoom) {
				setCountryZoom(false);
				setZoom(DealerFinderConstants.defaultZoom);
			}

			const userPosition = new google.maps.LatLng({ lat: userLocation.lat, lng: userLocation.lng });
			let closestMarker = ClusterService.getClosestMarker(userLocation);
			if (closestMarker) {
				let closestPoint = closestMarker.getPosition();
				if (closestPoint) {
					let currentMap = map as google.maps.Map;
					//create new bounds by creating a small circle and expanding it to the closestPoint
					let newBounds = new google.maps.Circle({ center: userLocation, radius: 10 })
						.getBounds()
						.extend(closestPoint);
					//update viewport to newBounds
					currentMap.fitBounds(newBounds);
					//remove one zoom level to ensure no marker is on the edge
					currentMap.setZoom(currentMap.getZoom() - 1);
					//set a minimum zoom because starting from very close-up can be disorienting
					if (currentMap.getZoom() > DealerFinderConstants.locationMinimumZoom) {
						currentMap.setZoom(DealerFinderConstants.locationMinimumZoom);
					}
				}
			}

			if (
				location.source === LocationSource.GEO_LOCATION ||
				location.source === LocationSource.SEARCH_LOCATION
			) {
				calculateDistance(userPosition);
				setLocation(userPosition, true);
			}

			if (location.source === LocationSource.GEO_LOCATION) {
				pushDealerfinder(countryCode, '', true, 'storestock');
			}
		}
	}, [location]);

	//use useCallback to prevent MarkerList from re-rendering
	const onClickMarker = useCallback((props, marker, event) => {
		if (marker && props) {
			setSelected({
				selectedMarker: marker,
				selectedStore: props.store,
				visible: true,
			});
			setCustomLocation(marker.position, props.store.name);
		}
	}, []);

	const onCloseInfoWindow = useCallback(() => {
		setSelected({ visible: false });
	}, []);

	const onOpenInfoWindow = useCallback(() => {
		//nothing to do
	}, []);

	const selectedDealer = DealerStore.getSelectedDealer();
	const filters = DealerStore.getFilters();

	useEffect(() => {
		if (selectedDealer) {
			let marker = ClusterService.getMarkerByID(selectedDealer.storeID);
			if (marker) {
				onSubmitMarker(marker, selectedDealer);
			}
		}
	}, [selectedDealer]);

	/* END OF HOOKS */

	const onMapReady = (mapProps: any, map: google.maps.Map<Element> | undefined) => {
		if (map) {
			setMap(map);
		}
	};

	const onClickMap = (e) => {
		setSelected({ visible: false });
	};

	const onSubmitSearch = (searchTerm: string) => {
		pushDealerfinder(countryCode, searchTerm, false, 'storestock');
		searchLocation(searchTerm, countryCode);
	};

	const onSubmitUserLocation = () => {
		getCurrentLocation();
	};

	const onSubmitMarker = (marker: google.maps.Marker, store: IStore) => {
		let markerPosition = marker.getPosition();
		if (markerPosition) {
			setSelected({
				selectedMarker: marker,
				selectedStore: store,
				visible: true,
			});
			setCustomLocation(markerPosition, store.name);
		}
	};
	
	return (
		<MapContainerLoader>
			<div className='dealer-finder-dealers b0_12 b5_10'>
				{localizations && (
					<>
						<SearchForm
							onSubmitSearch={onSubmitSearch}
							onSubmitUserLocation={onSubmitUserLocation}
							location={location}
							locationServiceAvailable={locationServiceAvailable}
							myStockshop={myStockshop}
						/>
						<DealerList myStockshop={myStockshop} />
					</>
				)}
			</div>
			<div className='dealer-finder-section modal-right-info b0_12 b5_14'>
				<div>
					<StockShopProduct myStockshop={myStockshop} />
				</div>
			</div>
			
		</MapContainerLoader>
	);
};

// const LoadingContainer = (props) => (
//   <div>Fancy loading container!</div>
// )

const connectWrapper = createConnect<StateProps, DispatchProps, OwnProps>()(MapContainer);

const apiWrapper = GoogleApiWrapper((props) => ({
	apiKey: 'AIzaSyCejZezrRZf7fMKEKUVunB_LHRgJ0ppIo0',
	language: props.language, //this is the MapContainer prop
	libraries: ['geometry'],
	//LoadingContainer: LoadingContainer,
	region: props.countryCode, //this is the MapContainer prop
	stockshop: props.stockshop, //this is the stockshop prop
}))(connectWrapper);

export default apiWrapper;
