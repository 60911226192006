import React, { FC, useEffect, useState } from 'react';
import createConnect from 'productdetail/stockshop/components/connect';
import { IStockShopState } from 'productdetail/stockshop/types';
import StockShopContainer from 'productdetail/stockshop/components/stockShopContainer';
import useSharedValue from 'shared/hooks/useSharedValue';
import StockShopStore from 'shared/context/stockShopStore';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	localizations: object;
	extensions: any;
	data: object;
	stockshop: IStockShopState;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;
const StockShop: FC<IProps> = (props) => {
	const { localizations, data, stockshop, reactId } = props;
	const { button } = stockshop;

	const onClickStockShop = (e: any) => {
		e.preventDefault();
		const sku: string = data['sku'] ?? '';
		StockShopStore.updateSubject(sku);
	};

	return (
		<>
			<button className='btn-sec variation02' onClick={onClickStockShop}>
				{button}
			</button>
			<StockShopContainer reactId={reactId} />
		</>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(StockShop);
