import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as stockshopActions from 'productdetail/stockshop/actions';
import * as dealerfinderActions from 'dealerfinder/actions';
import productActions from 'shared/reduceractions/getProducts';
import applicationActions from 'shared/reduceractions/getApplicationData';

function createConnect<StateProps, DispatchProps, OwnProps>() {
	const mapStateToProps = (state: any, ownProps: any): any => {
		let initialState = {
			localizations: state.localizations,
			extensions: state.extensions,
			general: state.general,
			stockshop: state.stockshop,
			dealerfinder: state.dealerfinder,
			data: undefined,
		};

		if (ownProps.reactId) {
			//data-react-data attributes are gathered in a single object for all components, map the state back to this specific component
			initialState = Object.assign(initialState, {
				data: state.data[ownProps.reactId],
				synchronizerToken: state.synchronizer[ownProps.reactId],
				stockshop: state[ownProps.reactId],
			});
		}
		return initialState;
	};

	const mapDispatchProps = (dispatch: any): any => {
		const actions = {
			...stockshopActions,
			...dealerfinderActions,
			...productActions,
			...applicationActions,
		};
		return bindActionCreators(actions, dispatch);
	};

	const connectContext = connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchProps
	);
	return connectContext;
}

export default createConnect;
