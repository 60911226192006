import fetch from 'isomorphic-fetch';
import { DealerfinderActions } from 'dealerfinder/types';
import { IHTTPResult } from 'shared/types';
import IStore from 'dealerfinder/objects/Store';

/* GET STORES */
function requestStores(isFetching: boolean) {
	return {
		type: DealerfinderActions.REQUEST_STORES,
		isFetching,
	};
}

function receiveStores(stores: [], result: IHTTPResult, position?: google.maps.LatLng) {
	return {
		type: DealerfinderActions.RECEIVE_STORES,
		stores,
		result,
		position,
	};
}

export function getStores(position?: google.maps.LatLng, countryCode?: string) {
	return (dispatch: any, getState: any) => {
		const { extensions, synchronizerToken, dealerfinder } = getState();
		let url = `${extensions.DealerfinderDispatchUrl}?getStores=getStores`;
		if (countryCode) {
			url += `&CountryCode=${countryCode}`;
		}

		let result: IHTTPResult = {
			status: 0,
			error: true,
		};

		dispatch(requestStores(true));

		return fetch(`${url}`, {
			method: 'GET',
			credentials: 'same-origin',
		})
			.then((response: any) => {
				result.status = response.status;
				result.error = result.status !== 200;
				return response.json();
			})
			.then((json: any) => {
				if (json && json.success === false) {
					result.error = true;
				} else if (json && json.data) {
					result.json = json.data;
				}
				if (json.success) {
					dispatch(receiveStores(json.data.stores, result, position));
				}
			})
			.catch((e: any) => {
				dispatch(receiveStores([], result, position));
			});
	};
}

/* CALCULATE DISTANCE */
function requestCalculateDistance() {
	return {
		type: DealerfinderActions.REQUEST_CALCULATE_DISTANCE,
	};
}

function receiveCalculateDistance(position: google.maps.LatLng) {
	return {
		type: DealerfinderActions.RECEIVE_CALCULATE_DISTANCE,
		position,
	};
}

export function calculateDistance(position: google.maps.LatLng) {
	return (dispatch: any, getState: any) => {
		dispatch(requestCalculateDistance());

		dispatch(receiveCalculateDistance(position));
	};
}

function requestSetLocation(position: google.maps.LatLng, isUserLocation?: boolean) {
	return {
		type: DealerfinderActions.REQUEST_SET_LOCATION,
		position,
		isUserLocation,
	};
}

export const setLocation = (position: google.maps.LatLng, isUserLocation?: boolean) => {
	return (dispatch: any, getState: any) => {
		dispatch(requestSetLocation(position, isUserLocation));
	};
};

export const onRenderCompleted = () => {
	return (dispatch: any, getState: any) => {
		dispatch({ type: DealerfinderActions.RENDER_COMPLETED });
	};
};

export const mapReady = () => {
	return (dispatch: any, getState: any) => {
		dispatch({ type: DealerfinderActions.MAP_READY });
	};
};

export const clearMap = () => {
	return (dispatch: any, getState: any) => {
		dispatch({ type: DealerfinderActions.CLEAR_MAP });
	};
};

export const setSelectedDealer = (dealer: IStore | undefined) => {
	return (dispatch: any, getState: any) => {
		dispatch({ type: DealerfinderActions.SELECT_DEALER, dealer });
	};
};
