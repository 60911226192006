import React, { FC } from 'react';
import IStore from 'dealerfinder/objects/Store';
import DealerStore from 'productdetail/stockshop/map/DealerStore';
import DealerCard from 'productdetail/stockshop/map/DealerCard';
import { IStockShop } from 'shared/messages/messages';

interface IProps {
	store: IStore;
	stockShop?: IStockShop;
	localizations: object;
	extensions: object;
}

const DealerInformation: FC<IProps> = (props) => {
	const { localizations, extensions, store, stockShop } = props;
	const query = encodeURIComponent(`${store.street} ${store.postalCode} ${store.city}`);
	const googleMapQueryURL = `https://www.google.com/maps/search/?api=1&query=${query}`;
	const phoneUrl = `tel:${store.phoneBusiness}`;
	const hasPhoneNumber = phoneUrl !== 'tel:';
	const homepage = store.homepage ? `https://${store.homepage}` : null;

	//useful for checking how many times a store is rendered
	// if (store.storeID === '100427') {
	//     console.log('render store', new Date(), store);
	// }

	const isSelected = DealerStore.getIsSelectedDealer(store);
	//const isVisible = DealerStore.getIsVisible(store);
	const isVisible = true;

	const inStock = (stockShop?.quantity ?? 0) > 0;

	const onClickDealer = (e) => {
		if (e.target.closest('[data-target]')) {
			//allow cta links through
			return;
		}

		e.preventDefault();

		if (isSelected) {
			DealerStore.clearSelectedDealer();
		} else {
			DealerStore.updateSelectedDealer(store);
		}
	};

	const cssSelected = isSelected ? 'selected' : '';
	const cssVisible = isVisible ? '' : 'hide';
	const cssInStock = inStock ? '' : 'transparent';
	const cssElite = store.elite ? 'elite' : '';
	const cssDealer = `dealer border mb ${cssSelected} ${cssVisible} ${cssInStock} ${cssElite}`;
	
	return (
		<div onClick={onClickDealer} className={cssDealer}>
			<div className='dealer-head'>
				<DealerCard
					localizations={localizations}
					mode='dealerfinder'
					store={store}
					inStock={inStock}
				/>
			</div>
			<div className='dealer-content'>
				<div className='dealer-address mb'>
					<span>
						{store.street}
						<br />
						{store.postalCode} {store.city}
						<br />
					</span>
					<a
						href={googleMapQueryURL}
						className='addr'
						target='_blank'
						rel='nofollow'
						data-target
						data-cta={localizations['dealerfinder.marker.dealer.title']}
					>
						<span>{localizations['dealerfinder.marker.dealer.title']}</span>
					</a>
				</div>
				<div className='dealer-contact'>
					{hasPhoneNumber && (
						<div className='dealer-phone mb'>
							<span className='contact-label'>
								{localizations['dealerfinder.marker.telephone.text']}&nbsp;
							</span>
							<span className='contact-value' data-target>
								{store.phoneBusiness}
							</span>
						</div>
					)}

					<div className='dealer-cta'>
						{hasPhoneNumber && (
							<div className='dealer-cta-item pr' data-target>
								<a className='btn-small' href={phoneUrl} target='_blank' rel='nofollow'>
									{localizations['dealerfinder.marker.phone.title']}
								</a>
							</div>
						)}
						{homepage && (
							<div className='dealer-cta-item' data-target>
								<a className='btn-small-theme02' href={homepage} target='_blank' rel='nofollow'>
									{localizations['dealerfinder.marker.webshop.text']}
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
			
		</div>
	);
};

export default React.memo(DealerInformation);
