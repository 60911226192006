import React, { useState, FC, useEffect, useRef } from 'react';
import createConnect from 'productdetail/stockshop/connect';
import { IDealerfinder } from 'dealerfinder/types';
import { Forms } from 'castlecss-forms';
import { TLocation, LocationSource } from 'shared/hooks/useLocation';
import DealerStore from 'dealerfinder/components/DealerStore';
import { IStockShopState } from '../types';

interface OwnProps {
	onSubmitUserLocation: Function;
	onSubmitSearch: Function;
	location: TLocation;
	locationServiceAvailable: boolean;
	myStockshop: IStockShopState;
}

interface StateProps {
	dealerfinder: IDealerfinder;
	history: any;
}

interface DispatchProps {
	getStores;
	setLocation;
	updateFilter;
}

type IProps = OwnProps & StateProps & DispatchProps;

const SearchForm: FC<IProps> = (props) => {
	const { location, locationServiceAvailable, onSubmitUserLocation, onSubmitSearch } = props;
	const { dealerfinder, myStockshop, history, getStores, setLocation, updateFilter } = props;
	const { localizations, extensions, filterStock } = myStockshop;
	const { stores } = dealerfinder;

	useEffect(() => {
		Forms();
	}, []);

	const userLocation: google.maps.LatLngLiteral | undefined = location?.location
		? { lat: location.location?.lat(), lng: location.location?.lng() }
		: undefined;
	let locationDescription = `🧭 ${localizations['dealerfinder.search.userlocation.no_location.text']}`;
	if (location && location?.hasLocation) {
		switch (location?.source) {
			case LocationSource.GEO_LOCATION:
				locationDescription = `🧭 ${localizations['dealerfinder.search.userlocation.my_location.text']}`;
				break;
			case LocationSource.SEARCH_LOCATION:
				locationDescription = `🧭 ${
					localizations['dealerfinder.search.userlocation.searched_location.text']
				}: ${location?.description ?? ''}`;
				break;
			case LocationSource.CUSTOM_LOCATION:
				locationDescription = `🧭 ${
					localizations['dealerfinder.search.userlocation.selected_location.text']
				}: ${location?.description ?? ''}`;
				break;
		}
	}

	const isPainting = DealerStore.getIsPainting();
	const [currentFilterStock, setFilterStock] = useState(filterStock);
	const postalCityInput = useRef<HTMLInputElement>(null);

	const onClickSearch = (e) => {
		e.preventDefault();
		let searchTerm = postalCityInput?.current?.value;
		if (searchTerm) {
			onSubmitSearch(searchTerm);
		}
	};

	const onChangeFilterStock = (e) => {
		setFilterStock(e.currentTarget.checked);
	};

	useEffect(() => {
		updateFilter(currentFilterStock);
	}, [currentFilterStock]);

	// //only useful for testing
	// const onClickStores = (e) => {
	// 	e.preventDefault();
	// 	getStores();
	// };

	// const onClickClear = (e) => {
	// 	e.preventDefault();
	// 	console.log('clear!', new Date());
	// 	ClusterService.clearMarkers();
	// 	ClusterService.repaint();
	// };

	// const onClickRepaint = (e) => {
	// 	e.preventDefault();
	// 	console.log('repaint!', new Date());
	// 	ClusterService.repaint();
	// };

	// const onClickPaint = (e) => {
	// 	e.preventDefault();
	// 	if (painting) {
	// 		DealerStore.setPaintingCompleted();
	// 	} else {
	// 		DealerStore.setPaintingStarted();
	// 	}
	// };

	return (
		<>
			<div className='postalCityContainer mt-b3-2x mt-b4-0'>
				<h1 className="title mb">
					{localizations['dealerfinder.search.form.title']}
				</h1>
				<form>
					<div className='form-fields label-top g gutter-none mb'>
						<div className='g ai-center'>

							<div className={`location-icon ${locationServiceAvailable == false ? 'location-icon-desabled': ''}`} onClick={()=> onSubmitUserLocation}>
								<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
								<g data-name="Layer 2">
								<g data-name="invisible box">
								<rect width="48" height="48" fill="none"/>
								</g>
								<g data-name="icons Q2">
								<path d="M44,22H39.9A16.1,16.1,0,0,0,26,8.1h0V4a2,2,0,0,0-4,0V8h0A16.1,16.1,0,0,0,8.1,22H4a2,2,0,0,0,0,4H8.1A16.1,16.1,0,0,0,22,39.9h0v4a2,2,0,0,0,4,0V40h0A16.1,16.1,0,0,0,39.9,26H44a2,2,0,0,0,0-4ZM24,36A12,12,0,1,1,36,24,12,12,0,0,1,24,36Z"/>
								<circle cx="24" cy="24" r="7"/>
								</g>
								</g>
								</svg>
							</div>
							<div className='b0_07'>
								<div className='form-field mb_0' data-castlecss-field>
									<label htmlFor='postalCityInput'>
										{localizations['dealerfinder.search.location.placeholder']}
									</label>
									<input
										ref={postalCityInput}
										type='text'
										className='postalCityInput'
										id='postalCityInput'
										name='postalCityInput'
									/>
								</div>
							</div>
							<button className='btn btn-theme02 search-btn' type='submit' onClick={onClickSearch}>
								{localizations['dealerfinder.search.button.text']}
							</button>
						</div>
						
						<label className='input-control input-checkbox custom-field'>
							<input
								type='checkbox'
								defaultChecked={filterStock}
								onChange={onChangeFilterStock}
							/>
							{localizations['product.stockshop.filter.onlywithstock.text']}
							<span className='input-indicator'></span>
						</label>
					</div>
					{locationServiceAvailable == false && 
						<div className='currentLocationDisabled mb'>
							<i className='negative'>
								<small>
									{localizations['dealerfinder.search.userlocation.button.disabled.text']}
								</small>
							</i>
						</div>
					}
					<div className='mb'>{locationDescription}</div>
					<div className='g'>
						
						{/*only useful for testing*/}
						{/*<div className="b0_12">
                            <button className="btn-theme02 mb" type="button" onClick={onClickClear}>Clear</button>
                        </div>
                        <div className="b0_12">
                            <button className="btn-theme02 mb" type="button" onClick={onClickRepaint}>Repaint</button>
                        </div>
                        <div className="b0_12">
                            <button className="btn-theme02 mb" type="button" onClick={onClickStores}>Get stores</button>
                        </div>
                        <div className="b0_12">
                            <button className="btn-theme02 mb" type="button" onClick={onClickPaint}>Paint (loader)</button>
                        </div>*/}
					</div>
				</form>
			</div>

			{/*brandspot (now elite) filter has been removed, see GIT in case other filters need to be added later*/}
		</>
	);
};

export default createConnect<StateProps, DispatchProps, OwnProps>()(SearchForm);
