import { IDealerfinder, DealerfinderActions, DealerfinderActionsTypes } from 'dealerfinder/types';
import IStore from 'dealerfinder/objects/Store';
import produce from 'immer';

const initialCompare: IDealerfinder = {
	isFetching: false,
	stores: [],
	storesResult: undefined,
	isDistanceCalculated: false,
	location: undefined,
	locationUser: undefined,
	mapReady: false,
	mapSelection: undefined,
};

const roundToOne = (num) => {
	if (isNaN(num)) {
		return undefined;
	}
	return (Math.round(num * 100) / 100).toFixed(1);
};

//sort -Infinity, NaN, Infinity to the end in random order
const sortNumbers = (a, b) => {
	if (isFinite(a - b)) {
		return a - b;
	} else {
		return isFinite(a) ? -1 : 1;
	}
};

const calculateDistance = (stores: IStore[], position: google.maps.LatLng) => {
	let debug = false;
	let startTime = new Date();
	let newStores: IStore[] = [];
	if (stores) {
		newStores = stores
			.map((store) => {
				const storePosition = new google.maps.LatLng({
					lat: parseFloat(store.latitude),
					lng: parseFloat(store.longitude),
				});
				let distanceMeters =
					google.maps.geometry.spherical.computeDistanceBetween(position, storePosition) / 1000;
				let distanceRounded = roundToOne(distanceMeters);
				store.distance = distanceRounded;
				return store;
			})
			.sort((a, b) => sortNumbers(a.distance, b.distance));

		const eliteDealerIdx = newStores.findIndex((store) => store.elite);
		if (eliteDealerIdx > 0) {
			newStores = [newStores[eliteDealerIdx]].concat(
				newStores.filter((store, idx) => idx != eliteDealerIdx)
			);
		}
	}
	let endTime = new Date();
	if (debug) {
		let elapsedTime = endTime.getTime() - startTime.getTime();
	}
	return newStores;
};

export const reduceDealerfinder = produce(
	(draft: IDealerfinder, action: DealerfinderActionsTypes) => {
		if (!draft) {
			return initialCompare;
		}

		/*
		 * NOTE: isFetching is used to show the loading screen starting from REQUEST_STORES and ending on RENDER_COMPLETED
		 * There is not really a need to have different progress indicators for RECEIVE_STORES, REQUEST_CALCULATE_DISTANCE, RECEIVE_CALCULATE_DISTANCE.
		 * This would only increase the amount of re-renders.
		 */

		switch (action.type) {
			case DealerfinderActions.REQUEST_STORES: {
				const { isFetching } = action;
				draft.isFetching = isFetching;
				draft.storesResult = undefined;
				return draft;
			}

			case DealerfinderActions.RECEIVE_STORES: {
				const { stores, position, result } = action;

				draft.storesResult = result;

				if (draft.locationUser) {
					//calculate distance based on user location
					const locationUser = new google.maps.LatLng({
						lat: draft.locationUser.lat,
						lng: draft.locationUser.lng,
					});
					draft.stores = calculateDistance(stores, locationUser);
					draft.isDistanceCalculated = true;
				} else if (position) {
					//defer distance calculation until user location is known
					draft.stores = stores;
				} else {
					//defer distance calculation until user location is known
					draft.stores = stores;
					draft.isDistanceCalculated = false;
				}

				return draft;
			}

			case DealerfinderActions.RENDER_COMPLETED: {
				if (draft.isFetching) {
					draft.isFetching = false;
				}
				return draft;
			}

			case DealerfinderActions.REQUEST_CALCULATE_DISTANCE: {
				return draft;
			}

			case DealerfinderActions.RECEIVE_CALCULATE_DISTANCE: {
				const { position } = action;

				if (draft.stores) {
					draft.stores = calculateDistance(draft.stores, position);
					draft.isDistanceCalculated = true;
				}

				return draft;
			}

			case DealerfinderActions.REQUEST_SET_LOCATION: {
				const { position, isUserLocation } = action;
				if (position) {
					const newPosition = { lat: position.lat(), lng: position.lng() };
					draft.location = newPosition;
					if (isUserLocation === true && draft.stores) {
						draft.locationUser = newPosition;
						draft.stores = calculateDistance(draft.stores, position);
						draft.isDistanceCalculated = true;
					}
				}

				return draft;
			}

			case DealerfinderActions.MAP_READY: {
				draft.mapReady = true;
				return draft;
			}

			case DealerfinderActions.CLEAR_MAP: {
				draft = initialCompare;
				return draft;
			}

			case DealerfinderActions.SELECT_DEALER: {
				const { dealer } = action;
				draft.mapSelection = {
					date: new Date(),
					dealer,
				};
				return draft;
			}

			default:
				break;
		}
	}
);

const localizations = (state = {}): object => {
	return state;
};

const extensions = (state = {}): object => {
	return state;
};

const synchronizerToken = (state = {}): object => {
	return state;
};

const reducers = {
	dealerfinder: reduceDealerfinder,
	localizations,
	extensions,
	synchronizerToken,
	history,
};

export default reducers;
