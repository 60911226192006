import React, { FC } from 'react';
import createConnect from 'productdetail/stockshop/components/connect';
import { IStockShopState } from 'productdetail/stockshop/types';
import ProductImageElement from 'shared/components/ProductImageElement';

interface OwnProps {
	myStockshop: IStockShopState;
}

interface StateProps {
	localizations: object;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;
const StockShopProduct: FC<IProps> = (props) => {
	const { myStockshop } = props;
	const { product, productDataResult, localizations } = myStockshop;

	let subtitleData =
		product?.subtitles.length ?? 0 > 0
			? product?.subtitles.reduce(
					(accumulator, currentValue) => accumulator + ',' + currentValue
			  ) ?? ''
			: '';

	return (
		<>
			{product && (
				<>
					<h1 className='title mb'>{product.name}</h1>
					<div className='subtitle'>{subtitleData}</div>
					<div className='subtitle'>
						{localizations['product.stockshop.framesize.label']} {product.framesize}
						{localizations['product.stockshop.framesize.cm.label']}
					</div>
					<div className='img mb'>
						<ProductImageElement images={product.images} name={product.name} />
					</div>
				</>
			)}
		</>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(StockShopProduct);
