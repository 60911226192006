import fetch from 'isomorphic-fetch';
import { pushUpdateToCompare } from 'shared/libs/gaevents';

/**
 * PRODUCTLISTER ACTIONS CONSTANTS
 */
export const REQUEST_PRODUCTLISTER = 'REQUEST_PRODUCTLISTER';
export const RECEIVE_PRODUCTLISTER = 'RECEIVE_PRODUCTLISTER';

/**
 * PRODUCT ACTION CONSTANTS
 */
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';

/**
 * BASKET ACTION CONSTANTS
 */
export const REQUEST_ADDPRODUCTTOBASKET = 'REQUEST_ADDPRODUCTTOBASKET';
export const RECEIVE_ADDPRODUCTTOBASKET = 'RECEIVE_ADDPRODUCTTOBASKET';

/**
 * COMPARE ACTION CONSTANTS
 */
export const REQUEST_UPDATECOMPAREPRODUCT = 'REQUEST_UPDATECOMPAREPRODUCT';
export const RECEIVE_UPDATECOMPAREPRODUCT = 'RECEIVE_UPDATECOMPAREPRODUCT';

/**
 * COMPARE ACTION CONSTANTS
 */
export const REQUEST_GETCOMPAREDATA = 'REQUEST_GETCOMPAREDATA';
export const RECEIVE_GETCOMPAREDATA = 'RECEIVE_GETCOMPAREDATA';

/**
 * PRODUCT ACTIONS
 */
export function receiveProduct(url, index, json) {
	return {
		type: RECEIVE_PRODUCT,
		url,
		index,
		data: json,
	};
}

export function requestProduct(url, index) {
	return {
		type: REQUEST_PRODUCT,
		url,
		index,
	};
}

function fetchProduct(url, index) {
	return (dispatch) => {
		dispatch(requestProduct(url, index));
		return fetch(`${url}`)
			.then((response) => response.json())
			.then((json) => dispatch(receiveProduct(url, index, json)));
	};
}

export function fetchProductVariant(url, index) {
	return (dispatch, getState) => {
		return dispatch(fetchProduct(url, index));
	};
}

/**
 *  PRODUCTLISTER ACTIONS
 */

function requestProductLister(url) {
	return {
		type: REQUEST_PRODUCTLISTER,
		url,
	};
}

export function receiveProductLister(url, json) {
	return {
		type: RECEIVE_PRODUCTLISTER,
		url,
		data: json,
	};
}

function fetchProductLister(url) {
	return (dispatch) => {
		dispatch(requestProductLister(url));
		return fetch(`${url}`)
			.then((response) => response.json())
			.then((json) => dispatch(receiveProductLister(url, json)));
	};
}

export function fetchProductListerIfNeeded(url) {
	return (dispatch, getState) => {
		const { productLister } = getState();
		const { productListers } = productLister;

		if (!productListers[url]) {
			return dispatch(fetchProductLister(url));
		} else {
			return dispatch(receiveProductLister(url, productListers[url]));
		}
	};
}

/**
 *  PRODUCTLISTER BASKET ACTIONS
 */

function requestAddProductToBasket(index) {
	return {
		type: REQUEST_ADDPRODUCTTOBASKET,
		index,
	};
}

export function receiveAddProductToBasket(dispatchUrl, index, json) {
	return {
		type: RECEIVE_ADDPRODUCTTOBASKET,
		dispatchUrl,
		index,
		data: json,
	};
}

function addProductToBasket(sku, index, quantity, dispatchUrl, synchronizerToken) {
	return (dispatch) => {
		dispatch(requestAddProductToBasket(index));

		const body =
			'addProduct=addProduct&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveAddProductToBasket(dispatchUrl, index, json)));
	};
}

export function addProductToBasketBySku(sku, index, quantity) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl) {
			return dispatch(
				addProductToBasket(sku, index, quantity, basketDispatchUrl, synchronizerToken)
			);
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}

/**
 *  COMPARE ACTIONS
 */

function requestUpdateCompareProduct() {
	return {
		type: REQUEST_UPDATECOMPAREPRODUCT,
	};
}

export function receiveUpdateCompareProduct(index, json, compare) {
	return {
		type: RECEIVE_UPDATECOMPAREPRODUCT,
		index,
		data: json,
		compare,
	};
}

export function updateCompareProduct(sku, compare, index, source) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const compareDispatchUrl = extensions.CompareDispatchUrl;

		dispatch(requestUpdateCompareProduct());

		const body =
			'updateCompareProduct=updateCompareProduct&SKU=' +
			sku +
			'&compare=' +
			compare +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${compareDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveUpdateCompareProduct(index, json, compare)))
			.then((json) => pushUpdateToCompare(sku, source, compare));
	};
}

const requestGetCompareData = () => {
	return {
		type: REQUEST_GETCOMPAREDATA,
	};
}

const receiveGetCompareData = (data) => {
	return {
		type: RECEIVE_GETCOMPAREDATA,
		data
	};
}

export const getCompareData = () => {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const compareDispatchUrl = extensions.CompareDispatchUrl;
		const body =
			'getCompareData=getCompareData' +
			'&SynchronizerToken=' + synchronizerToken;

		dispatch(requestGetCompareData());

		return fetch(`${compareDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
			.then((response) => response.json())
			.then((json) => dispatch(receiveGetCompareData(json)))
		}
}