import { useState, useEffect } from 'react';
import { Observable, BehaviorSubject, OperatorFunction } from 'rxjs';
import { skip } from 'rxjs/operators';

const useSharedValue = <T extends {}>(
	subject: Observable<T>,
	skipFirst = true,
	op?: OperatorFunction<T, T>
): T => {
	if (subject instanceof BehaviorSubject == false) {
		throw new Error('only BehaviorSubject is supported!');
	}
	let mySub: BehaviorSubject<T> = subject as BehaviorSubject<T>;
	const [value, setState] = useState<T>(mySub.getValue());
	useEffect(() => {
		if (op) {
			//'op' can be used to pass a filter like "filter(s => s.type === 'test')", to prevent duplicate renders
			const sub = mySub
				.pipe(skip(skipFirst ? 1 : 0))
				.pipe(op)
				.subscribe((value) => setState(value));
			return () => sub.unsubscribe();
		} else {
			const sub = mySub.pipe(skip(skipFirst ? 1 : 0)).subscribe((value) => setState(value));
			return () => sub.unsubscribe();
		}
	});
	return value;
};

export default useSharedValue;
